import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { themeColors } from "../utils/themeColor";
import { useState } from "react";
import ordersService from "../api/services/ordersService";
import { getFormattedDate } from "../utils/utils";

Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: "https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Light.woff",
      fontWeight: 300,
    },
    {
      src: "https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Regular.woff",
      fontWeight: 400,
    },
    {
      src: "https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Medium.woff",
      fontWeight: 500,
    },
    {
      src: "https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com/Ubuntu-Bold.woff",
      fontWeight: 700,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Ubantu",
    flexDirection: "column",
    backgroundColor: "white",
  },
  headerContainer: {
    backgroundColor: themeColors.common.neutral95,
  },
  bodyContainer: {
    marginHorizontal: "34px",
    marginVertical: "16px",
  },
  infoRow: {
    display: "flex",
    marginVertical: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  col2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "250px",
  },
  header: {
    height: "109px",
    width: "auto",
    fontSize: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    marginLeft: "34px",
    backgroundColor: themeColors.common.neutral95,
  },
});

// Create Document Component
const NespressoInvoiceDocument = ({ orderDetails }) => {
  // const { id } = useParams();
  const pathname = window.location.pathname;
  // console.log("window.location: ", pathname.split("/"));
  const id = pathname.split("/")[2];
  // var id2 = url.searchParams.get("id");
  // console.log("id2: ", id);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [orderDetails2, setOrderDetails] = useState(orderDetails);

  const getOrderDetails = async () => {
    try {
      setLoading(true);
      const result = await ordersService.getById(id);
      setOrderDetails(result);
      
      setLoading(false);
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  const getProductBoldRow = ({ name, value }) => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          height: "32px",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "left",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <Text
          style={{
            fontSize: "12px",
            marginLeft: "12px",
            fontFamily: "Ubuntu",
            fontWeight: "medium",
            color: "black",
          }}
        >
          {name}
        </Text>
        <Text
          style={{
            fontSize: "12px",
            marginRight: "12px",
            fontFamily: "Ubuntu",
            fontWeight: "medium",
            color: "black",
            textAlign: "left",
          }}
        >
          {value}
        </Text>
      </View>
    );
  };

  const getProductRow = (isNonProductRow = false, { name, value }) => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          height: "32px",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "left",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <Text
          style={{
            fontSize: "12px",
            marginLeft: "12px",
            fontFamily: "Ubuntu",
            color: isNonProductRow ? "black" : "#6E6E73",
          }}
        >
          {name ?? "Prodotto"}
        </Text>
        <Text
          style={{
            fontSize: "12px",
            marginRight: "12px",
            color: isNonProductRow ? "black" : "#6E6E73",
            textAlign: "left",
            fontFamily: "Ubuntu",
          }}
        >
          {value}
        </Text>
      </View>
    );
  };

  const getRow = (paramName, value) => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          gap: "16px",
          marginRight: "20px",
          textAlign: "left",
        }}
      >
        <Text
          style={{
            fontSize: "12px",
            fontFamily: "Ubuntu",
            fontWeight: "medium",
            width: '100px',
          }}
        >
          {paramName}
        </Text>
        <Text
          style={{
            fontSize: "12px",
            color: "#6E6E73",
            textAlign: "left",
            fontFamily: "Ubuntu",
          }}
        >
          {value}
        </Text>
      </View>
    );
  };

  const getHeader = (subtitle) => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "48px",
          fontSize: "18px",
          borderBottom: "2px solid black",
          fontFamily: "Ubuntu",
          fontWeight: "medium",
        }}
      >
        <Text
          style={{
            width: "auto",
            textAlign: "center",
            fontFamily: "Ubuntu",
          }}
        >
          {subtitle}
        </Text>
      </View>
    );
  };

  useEffect(() => {
    // getOrderDetails();
  }, [ordersService]);

  return loading ? (
    <>
      <Text>Loading...</Text>
    </>
  ) : (
    <>
      <Document>
        <Page
          size="A4"
          style={{
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <View style={styles.headerContainer}>
            <View style={styles.header}>
              <Text
                style={{
                  fontFamily: "Ubuntu",
                  fontWeight: "light",
                }}
              >
                Ordine {orderDetails?.increment_id}
              </Text>
            </View>
          </View>
          <View style={styles.bodyContainer}>
            {getHeader("Dettagli ordine")}
            <View style={styles.infoRow}>
              {getRow("N° ordine", orderDetails?.increment_id)}
              <View style={styles.col2}>
                {getRow(
                  "Data ordine",
                  getFormattedDate(orderDetails?.created_at)
                )}
              </View>
            </View>
            <View style={styles.infoRow}>
              {getRow("Nome", orderDetails?.customer_firstname)}
              <View style={styles.col2}>
                {getRow("Cognome", orderDetails?.customer_lastname)}
              </View>
            </View>
            <View style={styles.infoRow}>
              {getRow("Email", orderDetails?.customer_email)}
            </View>
            <View
              style={{
                height: "16px",
              }}
            />
            {getHeader("Elenco prodotti")}
            {getProductBoldRow({ name: "Prodotto", value: "Qtà astucci" })}
            {orderDetails?.items?.map((product) =>
              getProductRow(false, {
                name: product.name,
                value: product.qty_ordered,
              })
            )}
            {getProductBoldRow({
              name: "Totale",
              value: orderDetails?.total_qty_ordered,
            })}
          </View>
        </Page>
      </Document>
    </>
  );
};

export default NespressoInvoiceDocument;

import React, { useEffect, useState } from "react";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";

import { StyledTableCell } from "../../components/OrderRowRetailer";
import SearchInput from "../../components/SearchInput";
import Pagination from "../../components/Pagination";
import TableContainer from "../../components/TableContainer";
import Container from "../../components/Container";
import ordersService from "../../api/services/ordersService";
import ProgressOrMessageIndicator from "../../components/ProgressErrorStateIndicator";
import {
  EMPTY_STATE_MESSAGE,
  PAGE_SIZE,
  PERSISTANT_STORAGE_KEY_SELECTED_STORE,
  PERSISTANT_STORAGE_KEY_USER,
  ROLE_SUPER_ADMIN,
} from "../../utils/constants";
import OrderRowRetailer from "../../components/OrderRowRetailer";
import OrderRowAdmin, {
  StyledTableAdminCell,
} from "../../components/OrderRowAdmin";
import { useLocalStorage } from "../../utils/localStorage";
import {
  STATE_NEW,
  STATE_PICKED_UP,
  STATE_READY,
} from "../../components/OrderState";

export const TableHeaderRetailer = () => {
  return (
    <>
      <TableHead>
        <TableRow>
          <StyledTableCell variant="head">Ordine n°</StyledTableCell>
          <StyledTableCell variant="head">Prezzo</StyledTableCell>
          <StyledTableCell variant="head">Nome</StyledTableCell>
          <StyledTableCell variant="head">Cognome</StyledTableCell>
          <StyledTableCell variant="head">Email</StyledTableCell>
          <StyledTableCell variant="head">Data</StyledTableCell>
          <StyledTableCell variant="head">Stato</StyledTableCell>
          <StyledTableCell variant="head"></StyledTableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export const TableHeaderAdmin = () => {
  return (
    <>
      <TableHead>
        <TableRow>
          <StyledTableAdminCell variant="head">Store</StyledTableAdminCell>
          <StyledTableAdminCell variant="head">Ordine n°</StyledTableAdminCell>
          <StyledTableAdminCell variant="head">Prezzo</StyledTableAdminCell>
          <StyledTableAdminCell variant="head">Nome</StyledTableAdminCell>
          <StyledTableAdminCell variant="head">Cognome</StyledTableAdminCell>
          <StyledTableAdminCell variant="head">Email</StyledTableAdminCell>
          <StyledTableAdminCell variant="head">Data</StyledTableAdminCell>
          <StyledTableAdminCell variant="head">Stato</StyledTableAdminCell>
          <StyledTableAdminCell variant="head">Check</StyledTableAdminCell>
          <StyledTableAdminCell variant="head"></StyledTableAdminCell>
        </TableRow>
      </TableHead>
    </>
  );
};

const ActiveOrders = ({ selectedStore }) => {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchCache2, setSearchCache2] = useState(null);
  const [uiMessage, setUiMessage] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [page, setPage] = useState(1);
  const [getUserDetails, saveUserDetails] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_USER
  );
  const [getStoreData, saveStoreData] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_SELECTED_STORE
  );
  const userDetails = JSON.parse(getUserDetails(PERSISTANT_STORAGE_KEY_USER));
  const role = userDetails?.user_type;

  const searchOrders = async (searchQuery, pageNo) => {
    try {
      setLoading(true);
      let results = await ordersService.search(searchQuery, pageNo);

      const filterGroups = results?.search_criteria?.filter_groups;
      const firstFilterGroup =
        filterGroups?.length > 0 ? filterGroups[0] : null;
      // console.log("firstFilterGroup: ", firstFilterGroup.filters);
      const firstFilter =
        firstFilterGroup?.filters?.length > 0
          ? firstFilterGroup?.filters[0]
          : null;
      // console.log("firstFilter: ", firstFilter);
      if (firstFilter?.value === searchQuery) {
        /* console.log(
          `firstFilter?.value ${firstFilter?.value} searchQuery: ${searchQuery} setting results.`
        ); */
        setSearchCache2({
          ...searchCache2,
          [firstFilter?.value]: results,
        });

        // console.log(`search cache should be updated`);
      }
      // console.log("search.results.items", results?.items);
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setUiMessage(error.message);
    }
  };

  const getOrders = async (storeId, pageNo) => {
    try {
      let results = [];
      // console.log("getOrders.stores: ", storeId);
      setLoading(true);
      if (storeId == null) {
        results = await ordersService.getAll(pageNo);
      } else {
        results = await ordersService.getFilteredOrders(
          storeId,
          pageNo,
          `${STATE_NEW},${STATE_READY},${STATE_PICKED_UP}`
        );
      }

      // console.log("results.items", results?.items);
      setLoading(false);
      setOrders(results?.items?.filter((obj) => obj.status !== "pending"));
      setTotalItems(results?.total_count);
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setUiMessage(error.message);
    }
  };

  useEffect(() => {
    // console.log("ActiveOrder: searchQuery: ", searchQuery);
    if (!searchQuery) {
      const storeId = getStoreData();
      getOrders(storeId, 1);
    } else {
      searchOrders(searchQuery, 1);
    }
  }, [searchQuery]);

  useEffect(() => {
    // console.log("for searchQuery: ", searchQuery);
    const results = searchCache2 ? searchCache2[searchQuery] : null;
    if (results != null) {
      setOrders(results.items?.filter((obj) => obj.status !== "pending"));
      setTotalItems(results.total_count);
      setLoading(false);
    }
  }, [searchCache2]);

  const onPageChanged = (pageNo) => {
    if (!searchQuery) {
      const storeId = JSON.parse(getStoreData());
      getOrders(storeId, pageNo);
    } else {
      searchOrders(searchQuery, pageNo);
    }
  };

  return (
    <Container>
      {(searchQuery?.length > 0 || orders?.length > 0) && (
        <SearchInput
          onQuery={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 300, maxWidth: "1200px" }}
          aria-label="customized table"
        >
          {loading ? (
            <ProgressOrMessageIndicator />
          ) : orders?.length == 0 ? (
            <ProgressOrMessageIndicator message={EMPTY_STATE_MESSAGE} />
          ) : (
            <>
              {role === ROLE_SUPER_ADMIN ? (
                <TableHeaderAdmin />
              ) : (
                <TableHeaderRetailer />
              )}
              <TableBody>
                {orders?.map((order) => {
                  if (role === ROLE_SUPER_ADMIN) {
                    return <OrderRowAdmin order={order} />;
                  } else {
                    return <OrderRowRetailer order={order} />;
                  }
                })}
              </TableBody>
            </>
          )}
        </Table>
        {orders?.length > 0 && totalItems > PAGE_SIZE && (
          <Pagination
            pageCount={Math.ceil(totalItems / PAGE_SIZE)}
            onChange={onPageChanged}
          />
        )}
      </TableContainer>
    </Container>
  );
};

export default ActiveOrders;

import { Typography, styled } from "@mui/material";
import { themeFonts } from "../utils/themeFont";

export const TypographyMediumStyle = {
  fontWeight: 500,
  fontSize: '17px',
  lineHeight: '19.53px',
  fontFamily: themeFonts.medium,
};

const TypographyMedium = styled(Typography)(({ theme, color }) => (TypographyMediumStyle));

export default TypographyMedium;
import React, { useEffect, useState } from "react";
import {
  Stack,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import PageHeading from "../../components/PageHeading";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { themeColors } from "../../utils/themeColor";
import HeadingContainer, {
  ResponsiveContentStyles,
} from "../../components/HeadingContainer";
import { TypographyMediumStyle } from "../../components/TypographyMedium";
import ActiveOrders from "./ActiveOrders";
import PastOrders from "./PastOrders";
import {
  AUTO_LOGOUT_DURATION,
  BROADCAST_CHANNEL_NEW_ORDERS,
  FETCH_NEW_ORDERS_INTERVAL,
  PERSISTANT_STORAGE_KEY_NEW_ORDERS,
  PERSISTANT_STORAGE_KEY_SELECTED_STORE,
  PERSISTANT_STORAGE_KEY_USER,
  ROLE_SUPER_RETAILER,
  
} from "../../utils/constants";
import Notification from "../../components/Notification";
import ordersService from "../../api/services/ordersService";
import { clearLocalStorage, useLocalStorage } from "../../utils/localStorage";
import { ROUTE_LOGIN } from "../../App";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Spacer from "../../components/Spacer";

const TAB_ORDERS_ACTIVE = 0;
const TAB_ORDERS_PAST = 1;

const StyledTab = styled(Tab)(({ theme, color }) => ({
  ...TypographyMediumStyle,
  textTransform: "none",
  padding: "0px 24px",
  color: themeColors.text.secondary,
  "&.Mui-selected.MuiTab-textColorPrimary": {
    color: themeColors.text.offBlack,
  },
  "&.MuiTabs-indicator": {
    backgroundColor: "blue",
  },
}));

const Orders = ({ selectedStore }) => {
  const navigate = useNavigate();
  const newOrdersReceivedChannel = new BroadcastChannel(
    BROADCAST_CHANNEL_NEW_ORDERS
  );
  const [selectedTab, setSelectedTab] = useState(TAB_ORDERS_ACTIVE);
  const [mostRecentOrder, setMostRecentOrder] = useState(null);
  const [getUserDetails, saveUserDetails] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_USER
  );
  const userDetails = JSON.parse(getUserDetails(PERSISTANT_STORAGE_KEY_USER));
  const role = userDetails?.user_type;
  const [getStoreData, saveStoreData] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_SELECTED_STORE
  );

  const [getNewOrdersCount, saveNewOrderCount] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_NEW_ORDERS
  );
  const newOrdersCountFromStorage = getNewOrdersCount();
  const [newOrdersCount, setNewOrdersCount] = useState(
    newOrdersCountFromStorage
  );

  const getRecentOrders = async () => {
    try {
      const recentOrders = await ordersService.getRecentOrders();
      
      const newOrdersCountApi = recentOrders?.items?.length;
      if (newOrdersCountApi > 0) {
        const lastOrder = recentOrders.items[recentOrders.items.length - 1];
        
        setMostRecentOrder(lastOrder);
      }
      setNewOrdersCount(newOrdersCountApi);
      saveNewOrderCount(newOrdersCountApi);
    } catch (error) {
      console.log("error:", error.message);
    }
  };

  const clearRecentOrders = async () => {
    try {
      const storeId = userDetails.store_code;
      const orderId = mostRecentOrder.order_id;
      const results = await ordersService.clearRecentOrders(storeId, orderId);
      if (results) {
        saveNewOrderCount(0);
      }
    } catch (error) {
      console.log("error:", error.message);
    }
  };

  useEffect(() => {
    newOrdersReceivedChannel.postMessage(newOrdersCount);
  }, [newOrdersCount]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const onClose = () => {
    setNewOrdersCount(0);
    clearRecentOrders();
  };

  const hasSessionExpired = () => {
    const currentTimeInMillis = new Date().getTime();
    const loginTime = userDetails.loginTime;
    const diff = currentTimeInMillis - loginTime;
    if (diff >= AUTO_LOGOUT_DURATION) {
      return true;
    }

    return false;
  };

  const logoutIfSessionExpired = () => {
    if (hasSessionExpired()) {
      clearLocalStorage();
      navigate(`${ROUTE_LOGIN}`);
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      getRecentOrders();
      logoutIfSessionExpired();
    }, FETCH_NEW_ORDERS_INTERVAL);

    return () => clearTimeout(id);
  }, [getRecentOrders]);

  useEffect(() => {
    // console.log("Orders: selectedStore: ", selectedStore);
    const storeId = getStoreData();
    if (selectedStore != null && selectedStore.store_id != storeId) {
      saveStoreData(parseInt(selectedStore?.store_id));
      window.location.reload();
    }
  }, [selectedStore]);

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Spacer space="40px" />
      {newOrdersCount > 0 && (
        <Notification newOrderCount={newOrdersCount} onClose={onClose} />
      )}
      <HeadingContainer addSpace={false}>
        <PageHeading title="Ordini" />
        {role === ROLE_SUPER_RETAILER && (
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="secondary tabs example"
            sx={{
              "& .MuiTabs-flexContainer": {
                gap: 3,
              },
            }}
          >
            <StyledTab value={TAB_ORDERS_ACTIVE} label="Ordini attivi" />
            <StyledTab value={TAB_ORDERS_PAST} label="Ordini chiusi" />
          </Tabs>
        )}
      </HeadingContainer>
      {selectedTab === TAB_ORDERS_ACTIVE ? (
        <ActiveOrders selectedStore={selectedStore} />
      ) : (
        <PastOrders />
      )}
    </Stack>
  );
};

export default Orders;

import { Box, Button, Stack, styled } from "@mui/material";
import { themeColors } from "../utils/themeColor";
import { themeFonts } from "../utils/themeFont";

// state
export const STATE_NEW = "new";
export const STATE_READY = "ready";
export const STATE_PICKED_UP = "picked_up";
export const STATE_CANCELLED = "canceled";

export const STATUS_LABEL_NEW = "Nuovo";
export const STATUS_LABEL_PREPARED = "Preparato";
export const STATUS_LABEL_COLLECTED = "Ritirato";
export const STATUS_LABEL_CANCELLED = "Annullato";

const getBackgroundColor = (label) => {
  // console.log("label: ", label);
  let color = themeColors.common.green;
  if (label.includes(STATE_NEW)) {
    color = themeColors.common.green;
  } else if (label.includes(STATE_READY)) {
    color = themeColors.common.yellow;
  } else if (label.includes(STATE_PICKED_UP)) {
    color = themeColors.common.lightBlue;
  } else if (label.includes(STATE_CANCELLED)) {
    color = themeColors.common.lightPink;
  }

  return color;
};

export const getStatusLabel = (state) => {
  let label = "Unknown";
  if (state === STATE_NEW) {
    label = STATUS_LABEL_NEW;
  } else if (state === STATE_READY) {
    label = STATUS_LABEL_PREPARED;
  } else if (state === STATE_PICKED_UP) {
    label = STATUS_LABEL_COLLECTED;
  } else if (state === STATE_CANCELLED) {
    label = STATUS_LABEL_CANCELLED;
  }

  return label;
};

export const getOrderStateStyle = (value) => {
  return {
    height: "32px",
    backgroundColor: getBackgroundColor(value),
    color: themeColors.common.neutral10,
    borderRadius: "8px",
    padding: "6px 12px",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "14px",
    fontFamily: themeFonts.medium,
    textTransform: "none",
    cursor: 'default',
    "&:disabled": {
      backgroundColor: themeColors.common.neutral95,
      color: themeColors.text.secondary,
      border: `1px solid ${themeColors.common.nesGray}`,
    },
    "&:enabled": {
      backgroundColor: getBackgroundColor(value),
      color: themeColors.common.neutral10,
    },
  };
};

const OrderState = (props) => {
  const { value, styles} = props;
  return (
    <Button
      {...props}
      textAlign="center"
      justifyContent="center"
      disableRipple
      sx={{
        ...getOrderStateStyle(value),
        ...styles,
      }}
    >
      {getStatusLabel(value)}
    </Button>
  );
};

export default OrderState;

import { styled } from "@mui/material";
import { themeFonts } from "../utils/themeFont";
import { themeColors } from "../utils/themeColor";

const Dot = styled('div')(({ theme, color }) => ({
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  backgroundColor: themeColors.brand.primary,
}));

export default Dot;
import { accessToken } from "../../utils/token";
import PlaceholderApiProvider from "../serviceProviders/PlaceholderApiProvider";

class AuthService extends PlaceholderApiProvider {
  async login(data) {
    return this.api.post(`/rest/V1/admin/user/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async requestPasswordResetEmail(email) {
    // this.api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}` 
    return this.api.post(`/rest/V1/admin/password/forgot/`, {
      email: email
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async resetPassword(data) {
    return this.api.post(`/rest/V1/admin/password/reset/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

const authService = new AuthService();

export default authService;

import * as React from "react";
import {
  Stack,
  Pagination as BasePagination,
  Divider,
  PaginationItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { themeFonts } from "../utils/themeFont";
import { themeColors } from "../utils/themeColor";

const Pagination = ({ pageCount, page, onChange }) => {
  // const pageCount = 14;
  return (
    <>
      <Stack
        spacing={2}
        sx={{
          padding: "16px 12px",
          alignItems: "center",
          justifyContent: "center",
          width: "fill-available",
        }}
      >
        <BasePagination
          variant="text"
          count={pageCount}
          shape="rounded"
          color="primary"
          page={page}
          onChange={(event, page) => {
            onChange(page);
          }}
          renderItem={(item) => {
            // console.log("item", item);
            return (
              <PaginationItem
                slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
                {...item}
                sx={{
                  "&.MuiPaginationItem-textPrimary": {
                    color: themeColors.text.secondary,
                    fontSize: "15px",
                    fontFamily: themeFonts.regular,
                    fontWeight: 500,
                    wordWrap: "break-word",
                    border: `${
                      item.type === "page" ? "1px solid #E8E8ED" : "none"
                    }`,
                  },
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              />
            );
          }}
        />
      </Stack>
      <Divider />
    </>
  );
};

export default Pagination;

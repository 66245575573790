import * as React from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { themeColors } from "../utils/themeColor";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { Stack, useMediaQuery } from "@mui/material";
import SidebarMenuItem from "./SidebarMenuItem";
import { useTheme } from "@mui/material/styles";
import {
  BROADCAST_CHANNEL_NEW_ORDERS,
  GLOBAL_TOOLBAR_HEIGHT,
} from "../utils/constants";
import Toolbar from "./Toolbar";
import { ROUTE_HOME, ROUTE_INVENTORY, ROUTE_ORDERS } from "../App";
import { useState } from "react";
import { useEffect } from "react";
import Dot from "./Dot";

const DRAWER_WIDTH = 360;

const MENU_ITEM_ORDERS = "orders";
const MENU_ITEM_INVENTORY = "inventory";

export default function SideBar({ state, anchor, toggleDrawer }) {
  const newOrdersReceivedChannel = new BroadcastChannel(
    BROADCAST_CHANNEL_NEW_ORDERS
  );
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  const theme = useTheme();
  const [menuItem, setMenuItem] = useState(MENU_ITEM_ORDERS);
  const navigate = useNavigate();
  const desktopView = useMediaQuery(theme.breakpoints.up("desktopSm"));

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname === ROUTE_HOME || pathname === ROUTE_ORDERS) {
      setMenuItem(MENU_ITEM_ORDERS);
    } else if (pathname === ROUTE_INVENTORY) {
      setMenuItem(MENU_ITEM_INVENTORY);
    }
  }, [window.location.pathname]);

  const onClickSideMenuItem = (id) => {
    setMenuItem(id);
    if (id === MENU_ITEM_ORDERS) {
      navigate(`${ROUTE_ORDERS}`);
    } else if (id === MENU_ITEM_INVENTORY) {
      navigate(`${ROUTE_INVENTORY}`);
    }
  };

  newOrdersReceivedChannel.addEventListener("message", (event) => {
    setNewOrdersCount(event.data);
  });

  const list = () => (
    <>
      <Stack
        sx={{
          background: themeColors.common.neutral10,
          padding: "24px 32px",
          marginTop: "18px",
          display: {
            tablet: "flex",
            desktopSm: "none",
            desktopLg: "none",
          },
        }}
      >
        <CloseIcon
          fontSize="medium"
          sx={{
            width: "32px",
            height: "32px",
            color: themeColors.common.white,
            cursor: "pointer",
          }}
          onClick={toggleDrawer("left", false)}
        />
      </Stack>
      <Stack
        sx={{
          width: {
            tablet: "auto",
            desktopSm: "auto",
            desktopLg: "auto",
          },
          height: "100%",
          background: themeColors.common.neutral10,
          padding: "24px 8px",
          gap: "8px",
        }}
        onClick={toggleDrawer("left", false)}
        onKeyDown={toggleDrawer("left", false)}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background:
              menuItem === MENU_ITEM_ORDERS
                ? themeColors.common.maroonHalf
                : themeColors.common.neutral20,
            borderRadius: "50px",
            height: "48px",
            padding: "0px 16px",
            cursor: "pointer",
          }}
          onClick={() => {
            onClickSideMenuItem(MENU_ITEM_ORDERS);
          }}
        >
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="start"
          >
            <Stack>
              {newOrdersCount > 0 && (
                <Stack
                  justifyContent="end"
                  alignItems="end"
                  sx={{
                    display: {
                      tablet: "none",
                      desktopSm: "flex",
                      desktopLg: "none",
                    },
                  }}
                >
                  <Dot />
                </Stack>
              )}
              <ShoppingCartIcon
                fontSize="small"
                sx={{
                  color: themeColors.common.white,
                  width: "24px",
                  height: "24px",
                  margin: "4px",
                }}
              />
            </Stack>
            <SidebarMenuItem
              sx={{
                display: {
                  tablet: "flex",
                  desktopSm: "none",
                  desktopLg: "flex",
                },
              }}
            >
              Ordini
            </SidebarMenuItem>
          </Stack>
          {newOrdersCount > 0 && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                background: themeColors.brand.primary,
                width: "24px",
                height: "24px",
                borderRadius: "20px",
                display: {
                  tablet: "flex",
                  desktopSm: "none",
                  desktopLg: "flex",
                },
              }}
            >
              <SidebarMenuItem
                sx={{
                  color: themeColors.common.white,
                }}
              >
                {newOrdersCount}
              </SidebarMenuItem>
            </Stack>
          )}
        </Stack>
        <Stack
          id="inventory"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background:
              menuItem === MENU_ITEM_INVENTORY
                ? themeColors.common.maroonHalf
                : themeColors.common.neutral20,
            borderRadius: "50px",
            height: "48px",
            padding: "0px 16px",
            cursor: "pointer",
          }}
          onClick={() => {
            onClickSideMenuItem(MENU_ITEM_INVENTORY);
          }}
        >
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="start"
          >
            <Inventory2OutlinedIcon
              fontSize="small"
              sx={{
                color: themeColors.common.white,
                width: "24px",
                height: "24px",
                margin: "4px",
              }}
            />
            <SidebarMenuItem
              sx={{
                display: {
                  tablet: "flex",
                  desktopSm: "none",
                  desktopLg: "flex",
                },
              }}
            >
              Magazzino
            </SidebarMenuItem>
          </Stack>
        </Stack>
      </Stack>
    </>
  );

  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer
          variant={desktopView ? "persistent" : "temporary"}
          anchor={anchor}
          sx={{
            width: {
              tablet: DRAWER_WIDTH,
              desktopSm: "80px",
              desktopLg: DRAWER_WIDTH,
            },
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              background: themeColors.common.neutral10,
              width: {
                tablet: DRAWER_WIDTH,
                desktopSm: "80px",
                desktopLg: DRAWER_WIDTH,
              },
              boxSizing: "border-box",
            },
          }}
          open={desktopView ? true : state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          <Toolbar
            sx={{
              height: {
                tablet: "90px",
                desktopSm: "90px",
                desktopLg: "90px",
              },
            }}
          />
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

import { Typography, styled } from "@mui/material";
import { themeFonts } from "../utils/themeFont";

export const TypographySmallStyle = {
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '14px',
  fontFamily: themeFonts.regular,
};

const TypographySmall = styled(Typography)(({ theme, color }) => (TypographySmallStyle));

export default TypographySmall;
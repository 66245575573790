import { themeFonts } from "../utils/themeFont";
import { themeColors } from "../utils/themeColor";
import { Stack, styled, TableRow, Box } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import OrderState, {
  STATE_PICKED_UP,
  STATE_NEW,
  STATE_READY,
} from "./OrderState";

import IconArrowRight from "../../src/assets/images/ic-arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { getFormattedDateTime } from "../utils/utils";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: themeColors.common.neutral95,
  },
  // hide last border
  /*  "&:last-child td, &:last-child th": {
    border: 0,
  }, */
  margin: "0px",
  height: "48px",
  minHeight: "0px",
  borderBottom: "1px #D9D9D9 solid",
  background: themeColors.common.white,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: themeColors.text.secondary,
    fontFamily: themeFonts.regular,
    fontSize: "16px",
    padding: "0px",
    margin: "0px",
    "&:nth-child(8)": {
      width: "auto",
    },
  },
  "&.MuiTableCell-head": {
    color: theme.palette.common.black,
    wordWrap: "break-word",
    fontSize: "15px",
    padding: "0px",
    margin: "0px",
    height: "31.5px",
    fontWeight: 500,
    fontFamily: themeFonts.medium,
    "&:nth-child(7)": {
      width: {
        tablet: "10px",
        desktopSm: "210px",
        desktopLg: "210px",
      },
    },
  },
  "&:first-child": {
    padding: "8px 0px 8px 12px",
  },
  "&:last-child": {
    padding: "8px 12px 8px 0px",
  },
}));

const OrderRowRetailer = ({ order, activeOrder = true }) => {
  const navigate = useNavigate();
  const onOrderClicked = () => {
    // navigate(`/orderDetails/${order.entity_id}`);
    window.location.href = `/orderDetails?id=${order.entity_id}`;
  };

  const formattedPrice = order.grand_total.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  });

  return (
    <>
      <StyledTableRow key={order.increment_id}>
        <StyledTableCell component="th" scope="row">
          {order.increment_id}
        </StyledTableCell>
        <StyledTableCell align="left">€ {formattedPrice}</StyledTableCell>
        <StyledTableCell align="left">
          {order.customer_firstname}
        </StyledTableCell>
        <StyledTableCell align="left">
          {order.customer_lastname}
        </StyledTableCell>
        <StyledTableCell align="left">{order.customer_email}</StyledTableCell>
        <StyledTableCell align="left">
          {getFormattedDateTime(order?.created_at)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {activeOrder && (
            <Stack
              direction="row"
              gap={0.5}
              sx={{
                display: {
                  tablet: "none",
                  desktopSm: "flex",
                  desktopLg: "flex",
                },
              }}
            >
              {order.status == STATE_NEW && (
                <OrderState
                  value={STATE_NEW}
                  disabled={order.status != STATE_NEW}
                />
              )}

              {order.status == STATE_READY && (
                <OrderState
                  value={STATE_READY}
                  disabled={order.status != STATE_READY}
                />
              )}

              {order.status === STATE_PICKED_UP && (
                <OrderState
                  value={STATE_PICKED_UP}
                  disabled={order.status != STATE_PICKED_UP}
                />
              )}
            </Stack>
          )}
          <OrderState
            value={order.status}
            styles={{
              display: {
                tablet: "flex",
                desktopSm: activeOrder == false ? "flex" : "none",
                desktopSm: activeOrder == false ? "flex" : "none",
              },
            }}
          />
        </StyledTableCell>
        <StyledTableCell align="right" onClick={onOrderClicked}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              height: "fit-content",
              cursor: "pointer",
            }}
          >
            <img src={IconArrowRight} height="24px" width="24px" />
          </Box>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default OrderRowRetailer;

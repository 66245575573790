import config from "../../config";
import { getCache, nukeCache } from "../../utils/cache";
import { PAGE_SIZE } from "../../utils/constants";
import PlaceholderApiProvider from "../serviceProviders/PlaceholderApiProvider";

const CACHE_ORDERS_ADMIN = "admin-orders";
const CACHE_ORDERS = "retailer-orders";
const CACHE_ORDER_DETAILS = "order-details";

class OrdersService extends PlaceholderApiProvider {
  async _fetchAndCache(key, task) {
    
    const [getValue, setValue] = getCache(key);
    const result = await task();
    setValue(result);
    return result;
  }

  async getAll(pageNo) {
    const [getValue, setValue] = getCache(CACHE_ORDERS_ADMIN + pageNo);
    const cachedResult = getValue();
    const params = {
      "searchCriteria[sortOrders][0][field]": "entity_id",
      "searchCriteria[sortOrders][0][direction]": "DESC",
      "searchCriteria[pageSize]": PAGE_SIZE,
      "searchCriteria[currentPage]": pageNo,
    };
    return (
      cachedResult ??
      this._fetchAndCache(CACHE_ORDERS_ADMIN + pageNo, () => {
        let url = config.placeholderApiUrl + `/rest/V1/orders/`;
        
        return this.api.get(url, {
          params,
        });
      })
    );
  }

  getCachingKey(storeId, pageNo, statues) {
    return storeId + pageNo + statues ?? CACHE_ORDERS + pageNo + statues;
  }

  async getFilteredOrders(storeId, pageNo, statuses) {
    const cachingKey = this.getCachingKey(storeId, pageNo, statuses);
    const [getValue, setValue] = getCache(cachingKey);
    const cachedResult = getValue();
    return (
      cachedResult ??
      this._fetchAndCache(cachingKey, () => {
        const params = {
          "searchCriteria[filterGroups][1][filters][0][field]": "status",
          "searchCriteria[filterGroups][1][filters][0][conditionType]": "in",
          "searchCriteria[filterGroups][1][filters][0][value]": `${statuses}`,
          "searchCriteria[sortOrders][0][field]": "entity_id",
          "searchCriteria[sortOrders][0][direction]": "DESC",
          "searchCriteria[pageSize]": PAGE_SIZE,
          "searchCriteria[currentPage]": pageNo,
        };
        let url =
          config.placeholderApiUrl +
          `/rest/V1/orders/?searchCriteria[filterGroups][0][filters][0][conditionType]=in&searchCriteria[filterGroups][0][filters][0][field]=store_id&searchCriteria[filterGroups][0][filters][0][value]=${storeId}`;
        
        return this.api.get(url, {
          params,
        });
      })
    );
  }

  async getById(id) {
    return this.api.get(`/rest/V1/orders/${id}`);
  }

  async getRecentOrders(id) {
    return this.api.get(`/rest/V1/recent/order/`);
    /* return {
      status: true,
      items: [{
        order_id: 21,
      }]
    } */
  }

  async search(query, pageNo) {
    const trimmedQuery = query; // query.trim() ?? null;
    
    const params = {
      "searchCriteria[pageSize]": PAGE_SIZE,
      "searchCriteria[currentPage]": pageNo,
    };
    return this.api.get(
      `rest/V1/orders?searchCriteria[filter_groups][0][filters][0][field]=customer_firstname&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][0][value]=${trimmedQuery}&searchCriteria[filter_groups][0][filters][1][field]=customer_email&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][1][value]=${trimmedQuery}&searchCriteria[filter_groups][0][filters][2][field]=customer_lastname&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][2][value]=${trimmedQuery}&searchCriteria[filter_groups][0][filters][2][field]=increment_id&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][2][value]=${trimmedQuery}`,
      {
        params,
      }
    );
  }

  async updateStatus(id, state) {
    const data = {
      order_id: id,
      status: state,
    };
    nukeCache();
    return this.api.post(`/rest/V1/order/status/update`, data);
  }

  async clearRecentOrders(storeId, orderId) {
    const params = {
      orderId,
    };
    return this.api.get(
      `/rest/${storeId}/V1/recent/order/clear/?orderId=${orderId}`,
      {
        params,
      }
    );
  }
}

const ordersService = new OrdersService();
export default ordersService;

import { useState } from "react";
import { styled, TableRow, Radio } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { themeFonts } from "../utils/themeFont";
import { themeColors } from "../utils/themeColor";
import { STATE_NEW } from "./OrderState";

export const ICON_AWS_URL =
  "https://nespresso-front-static-images.s3.ap-south-1.amazonaws.com";

export const ROW_VARIANT_REGULAR = 1;
export const ROW_VARIANT_TOTAL = 2; // used to show total row

const ProductTableRow = styled(TableRow)(({ theme, type }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: `${
      type === ROW_VARIANT_TOTAL ? "transparent" : themeColors.common.neutral95
    }`,
  },
  margin: "0px",
  height: "48px",
  minHeight: "0px",
  borderBottom: `1px solid ${themeColors.common.neutral80}`,
  background: themeColors.common.white,
}));

export const ProductTableCell = styled(TableCell)(
  ({ theme, orderDetails, type, checked }) => ({
    [`&.${tableCellClasses.body}`]: {
      color: `${
        type === ROW_VARIANT_TOTAL
          ? themeColors.common.neutral10
          : themeColors.text.secondary
      }`,
      paddingRight: "12px",
      fontFamily: `${
        type === ROW_VARIANT_TOTAL ? themeFonts.medium : themeFonts.regular
      }`,
      wordWrap: "break-word",
      fontSize: `${type === ROW_VARIANT_TOTAL ? "15px" : "16px"}`,
      "&:not(:first-child)": {
        opacity: `${type === ROW_VARIANT_TOTAL ? 1 : checked ? 0.5 : 1}`,
      },
    },
    "&.MuiTableCell-head": {
      color: themeColors.common.neutral10,
      // wordWrap: "break-word",
      fontSize: "15px",
      lineHeight: "15px",
      fontFamily: themeFonts.medium,
      "&:nth-child(1)": {
        width: "4px",
        height: "16px",
        whiteSpace: "nowrap",
      },
      "&:nth-child(3)": {
        width: "96px",
        paddingRight: "12px",
      },
      "&:nth-child(4)": {
        width: "96px",
      },
    },
    "&:nth-child(2)": {
      paddingLeft: "16px",
    },
    "&:nth-child(1)": {
      whiteSpace: "nowrap",
      paddingLeft: "12px",
    },
    "&:nth-child(4)": {
      paddingRight: "12px",
      textAlign: "end",
      
    },
  })
);

const ProductRow = ({
  onItemChecked,
  variant = ROW_VARIANT_REGULAR,
  orderDetails,
  row: product,
}) => {
  const checkBoxInitialState = orderDetails?.status !== STATE_NEW;
  const [checkboxState, setCheckBoxState] = useState(checkBoxInitialState);

  return (
    <>
      <ProductTableRow
        status={product?.status}
        type={variant}
        key={product?.id}
      >
        {/* <ProductTableCell
          orderDetails={orderDetails}
          component="th"
          scope="row"
        >
          {variant == ROW_VARIANT_REGULAR && (
            <Radio
              sx={{
                width: "32px",
                height: "32px",
              }}
              size="small"
              icon={<img src={`${ICON_AWS_URL}/ic-radio-unchecked.svg`} />}
              checkedIcon={<img src={`${ICON_AWS_URL}/ic-radio-circled-checked.svg`} />}
              checked={checkboxState}
              onChange={(e) => {
                const checked = e.target.checked;
                onItemChecked(checked, product);
                setCheckBoxState(true);
              }}
            />
          )}
        </ProductTableCell> */}
        <ProductTableCell
          orderDetails={orderDetails}
          type={variant}
          align="left"
          checked={checkboxState}
        >
          {product?.name ?? "Totale"}
        </ProductTableCell>
        <ProductTableCell
          orderDetails={orderDetails}
          type={variant}
          align="right"
          checked={checkboxState}
        >
          {product?.qty_ordered ?? orderDetails?.total_qty_ordered}
        </ProductTableCell>
        <ProductTableCell
          orderDetails={orderDetails}
          type={variant}
          align="right"
          checked={checkboxState}
        >
          {product?.name
            ? "€ " + product?.row_total_incl_tax
            : "€ " + orderDetails?.grand_total}
        </ProductTableCell>
      </ProductTableRow>
    </>
  );
};

export default ProductRow;

import { Stack, TextField, Typography } from "@mui/material";
import TypographySmall, { TypographySmallStyle } from "./TypographySmall";
import { themeColors } from "../utils/themeColor";
import { themeFonts } from "../utils/themeFont";
import { InputStyle } from "./LabelledInputMaterial";
import SearchIcon from "../../src/assets/images/ic-search.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useState } from "react";

const LabelledPasswordReveal = (props) => {
  const [showPasswd, setShowPasswd] = useState(false);
  const { label, containerMargin, onKeyPressed} = props;
  return (
    <Stack
      direction="column"
      alignItems="start"
      mb={3}
      gap="4px"
      sx={{
        width: "100%",
        margin: containerMargin,
      }}
    >
      <Typography
        sx={{
          color: themeColors.text.secondary,
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: "14px",
          fontFamily: themeFonts.medium,
        }}
      >
        {label}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          ...InputStyle,
        }}
      >
        <input
          {...props}
          label={label}
          type={showPasswd ? "text" : "password"}
          onKeyUp={onKeyPressed}
          style={{
            ...InputStyle,
            border: "none",
            background: "transparent",
            padding: "0px",
          }}
        />
        {!showPasswd && <VisibilityOutlinedIcon
          fontSize="small"
          sx={{
            color: themeColors.text.secondary,
            color: themeColors.text.secondary,
            margin: '4px',
            width: '24px',
            height: '24px'
          }}
          onClick={() => {
            setShowPasswd(!showPasswd);
          }}
        />}
        {showPasswd && <VisibilityOffOutlinedIcon
          sx={{
            color: themeColors.text.secondary,
            margin: '4px',
            width: '24px',
            height: '24px'
          }}
          onClick={() => {
            setShowPasswd(!showPasswd);
          }}
        />}
      </Stack>
    </Stack>
  );
};

export default LabelledPasswordReveal;

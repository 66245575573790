import { Button as BaseButton, styled } from "@mui/material";
import { themeFonts } from "../utils/themeFont";
import { themeColors } from "../utils/themeColor";

const TYPE_PRIMARY = "primary";
const TYPE_SECONDARY = "secondary";
const TYPE_TERTIARY = "tertiary";
const TYPE_DULL = "dull";

const Button = (props) => {
  const { type = TYPE_PRIMARY, styles } = props;

  const getBgColor = (type) => {
    if(type === TYPE_PRIMARY) {
      return themeColors.common.maroonStrong;
    } else if(type === TYPE_SECONDARY) {
      return themeColors.common.white;
    } else {
      return themeColors.common.white;
    }
  }

  const getColor = (type) => {
    if(type === TYPE_PRIMARY) {
      return themeColors.common.white;
    } else if(type === TYPE_SECONDARY) {
      return themeColors.common.secondary;
    } else {
      return themeColors.common.maroonStrong;
    }
  }

  const getBorder = (type) => {
    if(type === TYPE_DULL) {
      return 'none';
    } else {
      return `1px ${themeColors.common.neutral80} solid`;
    }
  }

  return (
    <>
      <BaseButton
        {...props}
        sx={{
          fontSize: "15px",
          textAlign: "center",
          cursor: "pointer",
          fontWeight: 500,
          textTransform: 'none',
          fontFamily: themeFonts.medium,
          backgroundColor: `${getBgColor(type)}`,
          color: `${getColor(type)}`,
          lineHeight: "16.2px",
          width: "120px",
          height: "44px",
          padding: "14px 24px",
          borderRadius: "30px",
          border: getBorder(type),
          "&:hover": {
            // backgroundColor: themeColors.maincolor.tertiary,
          },
          "&:disabled": {
            backgroundColor: themeColors.common.neutral95,
            color: themeColors.common.neutral90,
            cursor: "none",
          },
          "&:enabled": {
            backgroundColor: `${getBgColor(type)}`,
            color: `${getColor(type)}`,
          },
          ...styles,
        }}
      >
        {props.children}
      </BaseButton>
    </>
  );
};

export default Button;

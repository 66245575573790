import * as React from "react";
import { TableContainer as BaseTableContainer, Paper } from "@mui/material";
import { themeColors } from "../utils/themeColor";

// Use this class to wrap the table.
const TableContainer = (props) => {
  return (
    <>
      <BaseTableContainer
        component={Paper}
        sx={{
          backgroundColor: themeColors.common.white,
          width: "fill-available",
          boxShadow: "none",
          marginBottom: "50px",
        }}
      >
        {props.children}
      </BaseTableContainer>
    </>
  );
};

export default TableContainer;

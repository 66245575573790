import config from "../config";
import moment from "moment";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const trimEllip = (source, length) => {
  if (source == null) {
    return "";
  }
  return source.length > length ? source.substring(0, length) + "..." : source;
};

export const isLocalDev = () => {
  if (config.placeholderApiUrl.includes("projectanddemoserver.com")) {
    return true;
  }

  if (window.location.href.includes("localhost")) {
    return true;
  }
  return false;
};

export const getFormattedDate = (date) => {
  if (!date) {
    return "";
  }
  const dateObj = new Date(date + " UTC");
  return moment(dateObj).format("DD/MM/YY");
};

const getTime = (date) => {
  return date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);
};

export const getFormattedDateTime = (date) => {
  if (!date) {
    return "";
  }
  const dateObj = new Date(date + " UTC");
  return `${getFormattedDate(dateObj)} - ${getTime(dateObj)}`;
  // return `${getTime(dateObj)}`
};

import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

import Spacer from "../../components/Spacer";
import { themeColors } from "../../utils/themeColor";
import { themeFonts } from "../../utils/themeFont";
import Card from "../../components/Card";
import Logo from "../../assets/images/logo-large.svg";
import StateLogin from "./StateLogin";
import StateResetPasswordRequested from "./StateResetPasswordRequested";
import StateSetPassword from "./StateSetPassword";
import {
  BROADCAST_CHANNEL_ID_LOGIN_PAGE,
  PERSISTANT_STORAGE_KEY_USER,
} from "../../utils/constants";
import { useEffect } from "react";
import StateSendEmail from "./StateSendEmail";
import { CardStyle } from "./LoginPage";
import { useCallback } from "react";
import { ROUTE_ORDERS } from "../../App";
import { useLocalStorage } from "../../utils/localStorage";
import { useNavigate } from "react-router-dom";

export const STATE_LOGIN = "login";
export const STATE_SEND_EMAIL = "reset-pass";
export const STATE_RESET_PASSWORD_REQUESTED = "reset-pass-requested";
export const STATE_SET_PASSWORD = "set-password";

const SetPasswordPage = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(STATE_LOGIN);
  const channel = new BroadcastChannel(BROADCAST_CHANNEL_ID_LOGIN_PAGE);
  const [getUserDetails, saveUserDetails] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_USER
  );
  const userDetails = JSON.parse(getUserDetails(PERSISTANT_STORAGE_KEY_USER));

  useEffect(() => {
    channel.postMessage("FROMLOGIN");
  }, [window.location.pathname]);

  const next = useCallback(async () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
    navigate(`${ROUTE_ORDERS}`);
  }, [navigate]);

  useEffect(() => {
    if (userDetails?.token) {
      // force redirect if user is already logged in
      next();
    }
  }, [userDetails?.token, next]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100vw",
        height: "100vh",
        background: themeColors.common.neutral95,
      }}
    >
      <Card sx={CardStyle}>
        <img src={Logo} />
        <Spacer space="14px" />
        <StateSetPassword setUiState={setUiState} />
      </Card>
    </Stack>
  );
};

export default SetPasswordPage;

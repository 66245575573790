import { Toolbar as BaseToolbar, Stack, styled } from "@mui/material";

// This component is used to create an empty space in the DOM of given height
// If space to be given is 40px, pass space="20px" when using this widget.
// it generates a component with height 40px
const Spacer = (props) => {
  const { space } = props;

  return (
    <Stack
      sx={{
        height: `calc(${space} * 2)`,
      }}
    ></Stack>
  );
};

export default Spacer;

import React, { useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

import Spacer from "../../components/Spacer";
import { themeFonts } from "../../utils/themeFont";
import Button from "../../components/Button";
import { themeColors } from "../../utils/themeColor";
import LabelledPasswordReveal from "../../components/LabelledPasswordReveal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTE_HOME, ROUTE_LOGIN } from "../../App";
import authService from "../../api/services/authService";

const StateSetPassword = ({ setUiState }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const [value, setValue] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const minLength = 12;

  const getPasswordStrength = () => {
    if (value.length < 3) return "Very weak";
    else if (value.length >= 3 && value.length < 6) return "Weak";
    else if (value.length >= 6 && value.length < 10) return "La password è ok";
    else if (value.length >= 10) return "Very strong";
  };

  const onFieldChanged = (event) => {
    event.preventDefault();
    setValue(event.target.value);
    setErrorMessage(null);
  };

  const getProgressColor = () => {
    if (value.length < 3) return themeColors.common.maroon;
    else if (value.length >= 3 && value.length < 6)
      return themeColors.common.maroonHalf;
    else if (value.length >= 6 && value.length < 10)
      return themeColors.common.nesGreenLight;
    else if (value.length >= 10) return themeColors.common.nesGreen;
  };

  const next = () => {
    setTimeout(() => {
      window.location.reload();
    }, 300);
    navigate(`${ROUTE_LOGIN}`);
  };

  const onResetPasswordClicked = () => {
    if (value == null || value?.length < 4) {
      setErrorMessage("Invalid password");
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const data = JSON.stringify({
          id: parseInt(id),
          token: token,
          password: value,
        });
        const result = await authService.resetPassword(data);
        setLoading(false);

        if (result.status) {
          next();
        } else {
          setErrorMessage(result.message);
        }
      } catch (error) {
        console.log("error:", error.message);
        setLoading(false);
        setErrorMessage(error.message);
      }
    })();
  };

  function LinearProgressWithLabel(props) {
    return (
      <Stack
        sx={{
          marginY: "8px",
          width: "100%",
          gap: "6px",
        }}
      >
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="buffer"
            color="primary"
            valueBuffer={100}
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            sx={{
              color: themeColors.text.secondary,
              fontSize: "11px",
              fontWeight: 400,
              fontFamily: themeFonts.regular,
              wordWrap: "break-word",
            }}
          >
            {getPasswordStrength()}
          </Typography>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
      }}
    >
      <LabelledPasswordReveal
        label="Nuova password"
        id="newPassword"
        containerMargin="0px"
        placeholder="AVery1StrongPassword2#"
        onChange={onFieldChanged}
      />
      {value.length > 0 && (
        <LinearProgressWithLabel
          value={Math.min((value.length * 100) / minLength, 100)}
          sx={{
            bgcolor: "red",
            color: "hsl(var(--hue) 80% 40%)",
            "& .MuiLinearProgress-bar": {
              height: "6px",
              background: getProgressColor(),
            },
            "& .MuiLinearProgress-colorPrimary": {
              background: themeColors.common.neutral90,
            },
          }}
        />
      )}
      <Spacer space="12px" />
      <Typography
        sx={{
          color: themeColors.common.maroon,
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: themeFonts.medium,
          wordWrap: "break-word",
        }}
        my={errorMessage ? 2 : 0}
      >
        {errorMessage}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button
          mt="6px"
          mb="6px"
          styles={{
            height: "44px",
            width: "fit-content",
            marginY: "6px",
            padding: "14px 24px",
            fontSize: "18px",
            lineHeight: "21px",
            textAlign: "center",
          }}
          onClick={onResetPasswordClicked}
        >
          Cambia password
        </Button>
      )}
    </Stack>
  );
};

export default StateSetPassword;

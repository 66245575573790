import config from '../../config';
import { getCache } from '../../utils/cache';
import { PAGE_SIZE } from '../../utils/constants';
import PlaceholderApiProvider from '../serviceProviders/PlaceholderApiProvider';

const CACHE_INVENTORY = 'inventory';

class InventoryService extends PlaceholderApiProvider {

  async _fetchAndCache(key, task) {
    const [getValue, setValue]= getCache(key);
    const result = await task();
    setValue(result);
    return result;
  }

  getCachingKey(pageNo) {
    return CACHE_INVENTORY+pageNo;
  }

  async search(query, storeId, pageNo) {
    const params = {
      storeId,
      search_string: query?.trim(),
      page_size: PAGE_SIZE,
      page_number: pageNo,
    }
    const url = config.placeholderApiUrl + `/rest/V1/product/inventory/list/`;
    return this.api.get(url, {
      params
    });
  }

  async getAll(storeId, pageNo) {
    const [getValue, setValue]= getCache(this.getCachingKey(pageNo));
    const cachedResult = getValue();
    return cachedResult ?? this._fetchAndCache(this.getCachingKey(pageNo), () => {
      const params = {
        storeId,
        page_size: PAGE_SIZE,
        page_number: pageNo,
      }
      const url = config.placeholderApiUrl + `/rest/V1/product/inventory/list/`;
      return this.api.get(url, { 
        params
      });
    });
  }
}

export const inventoryService = new InventoryService();
export default inventoryService;

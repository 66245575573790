import { Typography, styled } from "@mui/material";
import { themeFonts } from "../utils/themeFont";

export const TypographyRegularStyle = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '13px',
  fontFamily: themeFonts.regular,
};

const TypographyRegular = styled(Typography)(({ theme, color }) => (TypographyRegularStyle));

export default TypographyRegular;
import { Box, Button, Stack, styled } from "@mui/material";
import { themeColors } from "../utils/themeColor";
import TypographyRegularStyle from "./TypographyRegular";
import { themeFonts } from "../utils/themeFont";
import SearchIcon from "../../src/assets/images/ic-search.svg";

const SearchInput = ({ onQuery }) => {
  return (
    <Stack
      justifyContent="start"
      alignItems="start"
      sx={{
        padding: "24px 0px",
        width: {
          tablet: "470px",
          desktopSm: "450px",
          desktopLg: "450px",
        },
      }}
    >
      <Stack
        direction="row"
        gap="12px"
        textAlign="center"
        alignItems="center"
        justifyContent="start"
        sx={{
          backgroundColor: themeColors.common.neutral95,
          height: "56px",
          width: "100%",
          padding: "0px 8px 0px 24px",
          borderRadius: "30px",
        }}
      >
        <img
          src={SearchIcon}
          sx={{
            width: "32px",
            height: "32px",
            padding: "4px",
          }}
        />
        <div className="search-input">
          <input
            type="text"
            placeholder="Cerca..."
            onChange={onQuery}
            style={{
              fontFamily: themeFonts.regular,
              height: "32px",
              border: "none",
              background: "transparent",
            }}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export default SearchInput;

import { CircularProgress, Stack } from "@mui/material";
import H2 from "./H2";

const ProgressOrMessageIndicator = ({message}) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        height: "50vh",
      }}
    >
      {message ? <H2>{message}</H2> : <CircularProgress />}
    </Stack>
  );
};

export default ProgressOrMessageIndicator;

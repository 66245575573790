import { Stack } from "@mui/material";
import { CONTENT_SIDEPADDING_DESKTOP_LG, CONTENT_SIDEPADDING_DESKTOP_SM, CONTENT_SIDEPADDING_TABLET } from "./HeadingContainer";

// This component takes care of the horinzontal margins in the reponsive manner.
const Container = (props) => {
  return (
    <Stack
      sx={{
        paddingX: {
          tablet: `${CONTENT_SIDEPADDING_TABLET}`,
          desktopSm: `${CONTENT_SIDEPADDING_DESKTOP_SM}`,
          desktopLg: `${CONTENT_SIDEPADDING_DESKTOP_LG}`,
        },
        backgroundColor: 'transparent',
      }}
    >
      {props.children}
    </Stack>
  );
};

export default Container;

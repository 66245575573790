import config from '../../config';
import { getCache } from '../../utils/cache';
import PlaceholderApiProvider from '../serviceProviders/PlaceholderApiProvider';

const CACHE_STORES = 'stores';

class StoresService extends PlaceholderApiProvider {

  async _fetchAndCache(key, task) {
    const [getValue, setValue]= getCache(key);
    const result = await task();
    setValue(result);
    return result;
  }

  async getAll() {
    const [getValue, setValue]= getCache(CACHE_STORES);
    const cachedResult = getValue();
    return cachedResult ?? this._fetchAndCache(CACHE_STORES, () => {
      return this.api.get(`/rest/V1/stores/lists/`);
    });
  }

  async getById(id) {
    return this.api.get(`/${id}`);
  }
}

const storesService = new StoresService();
export default storesService;

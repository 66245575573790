import { Stack } from "@mui/material";
import { themeColors } from "../utils/themeColor";
import Spacer from "./Spacer";

export const CONTENT_SIDEPADDING_TABLET = "32px";
export const CONTENT_SIDEPADDING_DESKTOP_SM = "116px";
export const CONTENT_SIDEPADDING_DESKTOP_LG = "104px";

const ResponsiveContentPadding = {
  tablet: `32px ${CONTENT_SIDEPADDING_TABLET} 0px ${CONTENT_SIDEPADDING_TABLET}`,
  desktopSm: `32px ${CONTENT_SIDEPADDING_DESKTOP_SM} 0px`,
  desktopLg: `24px ${CONTENT_SIDEPADDING_DESKTOP_LG} 0px`,
};

export const ResponsiveContentStyles = {
  backgroundColor: themeColors.common.neutral95,
  padding: ResponsiveContentPadding,
  width: "auto",
};

const HeadingContainer = (props) => {
  const {addSpace = true} = props;
  return (
    <Stack
      sx={{
        ...ResponsiveContentStyles
      }}
    >
      {addSpace && <Spacer space="40px" />}
      {props.children}
    </Stack>
  );
};

export default HeadingContainer;

import React, { useEffect, useState } from "react";
import InputBase from "@mui/material/InputBase";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Stack,
  Box,
  Typography,
  Modal,
  FormControl,
  Button,
  styled,
  alpha,
} from "@mui/material";
import { themeColors } from "../utils/themeColor";
import Spacer from "./Spacer";
import { themeFonts } from "../utils/themeFont";

const DialogButton = styled(Button)(({ theme, width }) => ({
  fontWeight: 400,
  fontSize: "16px",
  color: themeColors.common.maroon,
  backgroundColor: "transparent",
  fontFamily: themeFonts.regular,
  lineHeight: "16px",
  margin: "0px",
  padding: 1,
  textTransform: "none",
  width: width,
  height: "40px",
}));

const ConfirmationModal = (props) => {
  const {
    title,
    desc,
    primaryBtnLabel,
    secondaryBtnLabel,
    open,
    setOpen,
    onActionConfimed,
    styles,
  } = props;
  const handleClose = () => setOpen(false);

  return (
    <div /* style={{
      width: '443px',
      height: '332px',
      backgroundColor: 'red',
      display: 'none',
    }} */
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          bgcolor="white"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "395px",
            height:"274",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "28px",
            textAlign: "center",
            p: 3,
            ...styles,
          }}
        >
          <WarningIcon
            sx={{
              color: themeColors.brand.primary,
            }}
          />
          <Spacer space="8px" />
          <Typography
            sx={{
              color: themeColors.text.secondary,
              fontSize: "32px",
              fontFamily: themeFonts.light,
              fontWeight: 300,
              wordWrap: "break-word",
            }}
          >
            {title}
          </Typography>
          <Spacer space="8px" />
          <Typography
            sx={{
              color: "#424245",
              fontSize: "16px",
              fontFamily: themeFonts.light,
              fontWeight: 400,
              textAlign: "start",
              wordWrap: "break-word",
            }}
          >
            {desc}
          </Typography>
          <Spacer space="12px" />
          <Stack
            direction="row"
            sx={{
              width: "100%",
              justifyContent: "right",
              gap: 1,
            }}
          >
            <DialogButton width="100px" onClick={handleClose}>
              {secondaryBtnLabel}
            </DialogButton>
            <DialogButton
              width="190px"
              onClick={() => {
                onActionConfimed();
                handleClose();
              }}
            >
              {primaryBtnLabel}
            </DialogButton>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;

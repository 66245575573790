import React from "react";
import { Divider, Stack, styled, Typography } from "@mui/material";
import { themeFonts } from "../../utils/themeFont";
import { themeColors } from "../../utils/themeColor";
import { getFormattedDate, getFormattedDateTime } from "../../utils/utils";

export const Heading = styled(Typography)(({ theme, color }) => ({
  fontWeight: 500,
  fontSize: "24px",
  lineHeight: "28px",
  color: themeColors.common.neutral10,
  fontFamily: themeFonts.medium,
  wordWrap: "break-word",
}));

const OrderDetailItem = ({ paramName, paramValue }) => {
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        justifyContent: "start",
        alignItems: "center",
        height: "32px",
        marginY: "8px",
        gap: {
          tablet: 2,
          desktopSm: 3,
          desktopLg: 3,
        },
      }}
    >
      <Typography
        sx={{
          color: themeColors.text.black,
          wordWrap: "break-word",
          fontSize: "16px",
          textAlign: "start",
          fontFamily: themeFonts.medium,
          width: {
            tablet: "96px",
            desktopSm: "96px",
            desktopLg: "96px",
          },
        }}
      >
        {paramName}
      </Typography>
      <Typography
        sx={{
          color: themeColors.text.secondary,
          padding: "0px",
          fontFamily: themeFonts.regular,
          textAlign: "start",
          width: {
            tablet: "auto",
            desktopSm: "auto",
            desktopLg: "auto",
          },
        }}
      >
        {paramValue}
      </Typography>
    </Stack>
  );
};

const OrderDetailsSummary = ({ orderDetails }) => {

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Heading
        sx={{
          marginY: {
            tablet: "18px",
            desktopSm: "26px",
            desktopLg: "26px",
          },
        }}
      >
        Dettagli ordine
      </Heading>
      <Divider />
      <Stack
        sx={{
          display: "grid",
          gridAutoRows: "minmax(10px, auto)",
          gridTemplateColumns: {
            tablet: "repeat(2, 1fr)",
            desktopSm: "repeat(1, 1fr)",
            desktopLg: "repeat(1, 1fr)",
          },
          marginBottom: {
            tablet: 3,
          },
        }}
      >
        <OrderDetailItem paramName="N° ordine" paramValue={orderDetails?.increment_id} />
        <OrderDetailItem paramName="Data ordine" paramValue={getFormattedDate(orderDetails?.created_at)} />
        <OrderDetailItem paramName="Nome" paramValue={orderDetails?.customer_firstname} />
        <OrderDetailItem paramName="Cognome" paramValue={orderDetails?.customer_lastname} />
        <OrderDetailItem paramName="Email" paramValue={orderDetails?.customer_email} />
      </Stack>
    </Stack>
  );
};

export default OrderDetailsSummary;

export const BROADCAST_CHANNEL_ID = "hamburger-event-channel";
export const BROADCAST_CHANNEL_ID_LOGIN_PAGE = "login-page-event-channel";

export const BROADCAST_CHANNEL_NEW_ORDERS = "new-orders-received-event-channel";

export const IS_DEV = false;

export const PERSISTANT_STORAGE_KEY_USER = "persistant.storage.key.user";
export const PERSISTANT_STORAGE_KEY_SELECTED_STORE =
  "persistant.storage.key.selected.store";
export const PERSISTANT_STORAGE_KEY_NEW_ORDERS =
  "persistant.storage.key.new-orders";

export const EMPTY_STATE_MESSAGE = "There is nothing to display here!";

export const ROLE_SUPER_ADMIN = "super_admin";
export const ROLE_SUPER_RETAILER = "retailer";

export const PAGE_SIZE = 10;

export const FETCH_NEW_ORDERS_INTERVAL = 10000; // 10 seconds

export const AUTO_LOGOUT_DURATION = 15 * 60 * 1000; // 15mins in millis

export const startPoint = "/digital-point";

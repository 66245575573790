const cache = {};

export function getCache(key) {
  const getValue = () => {
    const val = cache[key];

    return val;
  };
  const setValue = (value) => {
    cache[key] = value;
  };
  return [getValue, setValue];
}

// clear the cache
export function nukeCache() {
  for (const key in cache) {
    delete cache[key];
  }
}

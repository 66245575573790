import * as React from "react";
import Box from "@mui/material/Box";
import H2 from "./H2";

const PageHeading = ({ title }) => {
  return (
    <Box sx={{ display: "flex", mb: 2 }}>
      <H2>{title}</H2>
    </Box>
  );
};

export default PageHeading;

export const themeFonts = {
  light: "ubuntuLight",
  regular: "ubuntuRegular",
  medium: "ubuntuMedium",
  bold: "ubuntuBold",
};

// common weight names
/* 
300	Light
400	Normal (Regular)
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
*/
import "./App.css";
import * as React from "react";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Stack } from "@mui/material";
import Header from "./components/Header";
import SideBar, { list } from "./components/Sidebar";
import {
  ROLE_SUPER_ADMIN,
  BROADCAST_CHANNEL_ID_LOGIN_PAGE,
} from "./utils/constants";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Orders from "./Pages/Orders/Orders";
import OrderDetails from "./Pages/OrderDetails/OrderDetails";
import Magazzino from "./Pages/Magazzino";
import LoginPage from "./Pages/Login/LoginPage";
import SetPasswordPage from "./Pages/Login/SetPasswordPage";
import NespressoInvoiceDocument from "./Pages/NespressoInvoiceDocument";

export const ROUTE_HOME = "/";
export const ROUTE_ORDERS = "/orders";
export const ROUTE_LOGIN = "/login";
export const ROUTE_RESET_PASSWD = "/reset-password";
export const ROUTE_ORDER_DETAILS = "/orderDetails";
export const ROUTE_INVENTORY = "/magazzino";

const theme = createTheme({
  palette: {
    primary: {
      main: "#D70915",
    },
  },
  breakpoints: {
    values: {
      tablet: 767,
      desktopSm: 1440,
      desktopLg: 1648,
    },
  },
});

function App() {
  const anchor = "left";
  const [showNavBars, setShowNavBars] = useState(true);
  const [selectedStore, setSelectedStore] = useState(null);
  const channel = new BroadcastChannel(ROLE_SUPER_ADMIN);
  const loginChannel = new BroadcastChannel(BROADCAST_CHANNEL_ID_LOGIN_PAGE);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  channel.addEventListener("message", (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: true });
  });

  loginChannel.addEventListener("message", (event) => {
    setShowNavBars(false);
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const storeSelected = (store) => {
    setSelectedStore(store);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {showNavBars && (
            <Header
              toggleDrawer={toggleDrawer}
              state={state}
              setState={setState}
              onStoreChange={storeSelected}
            />
          )}
          <Stack direction="row">
            {showNavBars && (
              <SideBar
                toggleDrawer={toggleDrawer}
                state={state}
                anchor={anchor}
              />
            )}
            <Routes>
              <Route
                exact={true}
                path={`${ROUTE_HOME}`}
                element={<Orders selectedStore={selectedStore} />}
              />
              <Route
                exact={true}
                path={`${ROUTE_ORDERS}`}
                element={<Orders />}
              />
              <Route
                exact={true}
                path={`${ROUTE_LOGIN}`}
                element={<LoginPage />}
              />
              <Route
                exact={true}
                path={`${ROUTE_RESET_PASSWD}`}
                element={<SetPasswordPage />}
              />
              <Route
                exact={true}
                path={ROUTE_ORDER_DETAILS}
                element={<OrderDetails />}
              />
              <Route
                exact={true}
                path={ROUTE_INVENTORY}
                element={<Magazzino />}
              />
              <Route
                exact={true}
                path="/pdf"
                element={<NespressoInvoiceDocument />}
              />
            </Routes>
          </Stack>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;

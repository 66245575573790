import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Stack, Typography } from "@mui/material";

import Spacer from "../../components/Spacer";
import { themeFonts } from "../../utils/themeFont";
import LabelledInputMaterial from "../../components/LabelledInputMaterial";
import Button from "../../components/Button";
import { STATE_SEND_EMAIL } from "./LoginPage";
import LabelledPasswordReveal from "../../components/LabelledPasswordReveal";
import { isLocalDev, validateEmail } from "../../utils/utils";
import authService from "../../api/services/authService";
import { useLocalStorage } from "../../utils/localStorage";
import {
  PERSISTANT_STORAGE_KEY_SELECTED_STORE,
  PERSISTANT_STORAGE_KEY_USER,
  ROLE_SUPER_RETAILER,
} from "../../utils/constants";
import { ROUTE_ORDERS } from "../../App";
import { themeColors } from "../../utils/themeColor";

const StateLogin = ({ setUiState }) => {
  const navigate = useNavigate();
  const [isFormValid, setFormValid] = useState(false);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [getUserDetails, saveUserDetails] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_USER
  );
  const [getStoreData, saveStoreData] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_SELECTED_STORE
  );
  const userDetails = JSON.parse(getUserDetails(PERSISTANT_STORAGE_KEY_USER));

  const onFieldChanged = (event) => {
    const target = event.target;
    const fieldValue = target.value;
    // console.log("value: ", event.target.value);
    // console.log("id: ", event.target.id);
    event.preventDefault();

    const data = {
      ...formData,
    };
    data[event.target.id] = fieldValue;

    setFormData(data);
    setErrorMessage(null);
  };

  useEffect(() => {
    if (formData != null) {
      const email = formData["email"];
      const password = formData["password"];
      if (
        validateEmail(email) &&
        !isLocalDev() &&
        email?.length > 4 &&
        password?.length >= 4
      ) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
  }, [formData]);

  const next = useCallback(async () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
    navigate(`${ROUTE_ORDERS}`);
  }, [navigate]);

  useEffect(() => {
    if (userDetails?.token) {
      // force redirect if user is already logged in
      next();
    }
  }, [userDetails?.token, next]);

  const login = () => {
    if (formData == null) {
      setErrorMessage("Inserisci le credenziali di accesso");
      return;
    }

    const email = formData["email"];
    const password = formData["password"];
    // if (
    //   email == null ||
    //   (!validateEmail(email) && !isLocalDev()) ||
    //   email?.length < 4
    // ) {
    //   setErrorMessage("E-mail non valido");
    //   return;
    // }

    if (password == null || password?.length < 4) {
      setErrorMessage("Password non valida");
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const credentials = JSON.stringify({
          username: formData.email,
          password: formData.password,
        });
        const result = await authService.login(credentials);
        setLoading(false);
        
        if (result.token) {
          saveUserDetails({
            ...result,
            loginTime: new Date().getTime(),
          });
          if (result.stores) {
            
            saveStoreData(result.stores);
            next();
          } else {
            if (result.user_type === ROLE_SUPER_RETAILER) {
              setErrorMessage("Utente non trovato");
            }
          }
        }
      } catch (error) {
        console.log("error:", error.message);
        setLoading(false);
        setErrorMessage(error.message);
      }
    })();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
      }}
    >
      <LabelledInputMaterial
        label="Email"
        id="email"
        type="email"
        onChange={onFieldChanged}
      />
      <LabelledPasswordReveal
        label="Password"
        id="password"
        containerMargin="0px"
        onChange={onFieldChanged}
        onKeyPressed={handleKeyDown}
      />
      <Typography
        sx={{
          color: "#0084CE",
          fontSize: "13px",
          fontWeight: 700,
          lineHeight: "14px",
          fontFamily: themeFonts.bold,
          wordWrap: "break-word",
          width: "100%",
          textAlign: "right",
        }}
        mt={1}
        onClick={() => {
          setUiState(STATE_SEND_EMAIL);
        }}
      >
        Hai dimenticato la password ?
      </Typography>
      <Spacer space="12px" />
      <Typography
        sx={{
          color: themeColors.common.maroon,
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: themeFonts.medium,
          wordWrap: "break-word",
        }}
        my={errorMessage ? 2 : 0}
      >
        {errorMessage}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button mt="6px" mb="6px" /* disabled={!isFormValid} */ onClick={login}>
          Accedi
        </Button>
      )}
    </Stack>
  );
};

export default StateLogin;

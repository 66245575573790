import React, { useEffect, useState } from "react";
import {
  Stack,
  Divider,
  useMediaQuery,
  useTheme,
  Box,
  Switch,
  Typography,
  styled,
} from "@mui/material";

import PageHeading from "../../components/PageHeading";
import HeadingContainer from "../../components/HeadingContainer";
import OrderState, {
  STATE_CANCELLED,
  STATE_PICKED_UP,
  STATE_NEW,
  STATE_READY,
  STATUS_LABEL_CANCELLED,
  STATUS_LABEL_NEW,
  STATUS_LABEL_PREPARED,
} from "../../components/OrderState";
import OrderDetailsSummary from "./OrderDetailsSummary";
import OrderDetailsProducts from "./OrderDetailsProducts";
import Container from "../../components/Container";
import { themeFonts } from "../../utils/themeFont";
import { themeColors } from "../../utils/themeColor";
import { useParams } from "react-router-dom";
import ordersService from "../../api/services/ordersService";
import ProgresIndicator from "../../components/ProgressErrorStateIndicator";
import ConfirmationModal from "../../components/ConfirmationModal";
import OrderStateClickable from "../../components/OrderStateClickable";
import {
  PERSISTANT_STORAGE_KEY_USER,
  ROLE_SUPER_ADMIN,
} from "../../utils/constants";
import { useLocalStorage } from "../../utils/localStorage";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#2ECA45"
            : themeColors.common.lightGreen,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundColor: "black",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const OrderDetails = () => {
  const theme = useTheme();
  // const { id } = useParams();
  const search = window.location.search; // returns the url query string
  const params = new URLSearchParams(search);
  const id = params.get("id");

  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMarkOrderCollectedModal, setShowMarkOrderCollectedModal] =
    useState(false);
  const [showMarkOrderCancelledModal, setShowMarkOrderCancelledModal] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const isTablet = useMediaQuery(theme.breakpoints.down("desktopSm"));
  const [getUserDetails, saveUserDetails] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_USER
  );
  const userDetails = JSON.parse(getUserDetails(PERSISTANT_STORAGE_KEY_USER));
  const role = userDetails?.user_type;

  const getOrderDetails = async () => {
    try {
      setLoading(true);
      const result = await ordersService.getById(id);
      setOrderDetails(result);

      setLoading(false);
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, [ordersService]);

  const updateOrderStatus = async (status) => {
    try {
      setLoading(true);
      const result = await ordersService.updateStatus(id, status);
      setLoading(false);

      getOrderDetails();
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  const onMarkOrderReady = () => {
    updateOrderStatus(STATE_READY);
  };

  const onMarkOrderCollectedClicked = () => {
    if (orderDetails?.status === STATE_CANCELLED) {
      // a cancelled order cannot have its state changed.
      return;
    }
    setShowMarkOrderCollectedModal(true);
  };

  const onMarkOrderCollectedConfirmed = () => {
    updateOrderStatus(STATE_PICKED_UP);
  };

  const onMarkOrderCancelledClicked = () => {
    setShowMarkOrderCancelledModal(true);
  };

  const onMarkOrderCancelledConfirmed = () => {
    updateOrderStatus(STATE_CANCELLED);
  };

  const onMarkOrderDoneToggle = (event) => {
    const checked = event.target.checked;
    if (checked) {
      updateOrderStatus("done");
    }
  };

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <HeadingContainer>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <PageHeading title={`Ordine ${orderDetails?.increment_id ?? ""}`} />
            <Stack
              direction="row"
              sx={{
                margin: {
                  tablet: "8px 0px 32px 0px",
                  desktopSm: "0px 0px 24px 0px",
                  desktopLg: "0px 0px 24px 0px",
                },
                display: loading ? "none" : "flex",
                gap: 1,
              }}
            >
              
              {orderDetails?.status === STATE_NEW && (
                <OrderState
                  label={STATUS_LABEL_NEW}
                  value={STATE_NEW}
                  disabled={orderDetails?.status !== STATE_NEW}
                />
              )}

              {orderDetails?.status === STATE_READY && (
                <OrderState
                  label={STATUS_LABEL_PREPARED}
                  value={STATE_READY}
                  disabled={orderDetails?.status !== STATE_READY}
                />
              )}

              {orderDetails?.status === STATE_PICKED_UP && (
                <OrderState value={STATE_PICKED_UP} />
              )}

              {orderDetails?.status === STATE_CANCELLED && (
                <OrderState
                  label={STATUS_LABEL_CANCELLED}
                  value={STATE_CANCELLED}
                  disabled={orderDetails?.state !== STATE_CANCELLED}
                />
              )}
            </Stack>
          </Box>
          {role === ROLE_SUPER_ADMIN &&
            orderDetails?.extension_attributes?.order_check_flag === 0 && (
              <Stack
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "18px",
                    fontFamily: themeFonts.medium,
                  }}
                >
                  Check effettuato
                </Typography>
                <IOSSwitch onChange={onMarkOrderDoneToggle} />
              </Stack>
            )}
        </Stack>
      </HeadingContainer>
      <Container>
        {loading ? (
          <ProgresIndicator />
        ) : (
          <>
            <Stack
              justifyContent="center"
              alignItems="start"
              sx={{
                display: "flex",
                flexDirection: {
                  tablet: "column",
                  desktopSm: "row-reverse",
                  desktopLg: "row-reverse",
                },
                gap: {
                  tablet: "0px",
                  desktopSm: 3,
                  desktopLg: 5,
                },
              }}
            >
              <OrderDetailsSummary orderDetails={orderDetails} />
              <Divider
                orientation={isTablet ? "horizontal" : "vertical"}
                sx={{
                  width: {
                    tablet: "100%",
                    desktopSm: "0.5px",
                    desktopLg: "0.5px",
                  },
                  height: {
                    tablet: "0.5px",
                    desktopSm: "100vh",
                    desktopLg: "100vh",
                  },
                }}
              />
              <OrderDetailsProducts
                role={role}
                orderDetails={orderDetails}
                onMarkOrderPrepared={onMarkOrderReady}
                onMarkOrderCollectedConfirmed={onMarkOrderCollectedClicked}
                onMarkOrderCancelledClickedInProd={onMarkOrderCancelledClicked}
              />
            </Stack>
          </>
        )}
      </Container>
      <ConfirmationModal
        title="Vuoi segnare quest’ordine come ritirato?"
        desc="Assicurati che l’ordine sia stato preparato correttamente. Se vuoi proseguire, ricordati che una volta segnato come ritirato l’ordine verrà spostato negli ordini chiusi."
        primaryBtnLabel="Segna come ritirato"
        secondaryBtnLabel="Annulla"
        onActionConfimed={onMarkOrderCollectedConfirmed}
        open={showMarkOrderCollectedModal}
        setOpen={setShowMarkOrderCollectedModal}
        // styles={{
        //   width: "395px",
        //   height: "274px",
        // }}
      />
      <ConfirmationModal
        title="Vuoi segnare quest’ordine come annullato?"
        desc="Se vuoi proseguire, ricordati che una volta segnato come annullato l’ordine verrà spostato negli ordini chiusi."
        primaryBtnLabel="Segna come annullato"
        secondaryBtnLabel="Annulla"
        onActionConfimed={onMarkOrderCancelledConfirmed}
        open={showMarkOrderCancelledModal}
        setOpen={setShowMarkOrderCancelledModal}
        // styles={{
        //   width: "395px",
        //   height: "256px",
        // }}
      />
    </Stack>
  );
};

export default OrderDetails;

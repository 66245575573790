import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";

import Spacer from "../../components/Spacer";
import { themeFonts } from "../../utils/themeFont";
import LabelledInputMaterial from "../../components/LabelledInputMaterial";
import Button from "../../components/Button";
import {
  STATE_LOGIN,
  STATE_SEND_EMAIL,
  STATE_RESET_PASSWORD_REQUESTED,
  STATE_SET_PASSWORD,
} from "./LoginPage";
import { themeColors } from "../../utils/themeColor";

const StateResetPasswordRequested = ({ setUiState }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
      }}
    >
      <Typography
        mb={2}
        sx={{
          maxWidth: "320px",
          color: themeColors.common.neutral10,
          fontSize: "21px",
          fontWeight: 500,
          lineHeight: "21px",
          fontFamily: themeFonts.regular,
          wordWrap: "break-word",
          textAlign: "center",
        }}
      >
        Email di reimpostazione password inviata
      </Typography>
      <Typography
        sx={{
          maxWidth: "320px",
          color: themeColors.common.neutral10,
          fontSize: "16px",
          fontWeight: 400,
          fontFamily: themeFonts.regular,
          wordWrap: "break-word",
          textAlign: "center",
        }}
      >
        È stata inviata una email indirizzo che hai inserito. Per reimpostare la
        password, segui le istruzioni riportate nella mail.
      </Typography>
      <Spacer space="20px" />
      <Button
        mt="6px"
        mb="6px"
        onClick={() => {
          // setUiState(STATE_SET_PASSWORD);
          // window.close();
          // window.open('','_self').close();
          // window.open(window.location, '_self').close();
          window.top.close();
        }}
      >
        Fine
      </Button>
    </Stack>
  );
};

export default StateResetPasswordRequested;

export const themeColors = {
  maincolor: {
    tertiary: "#3D8705",
  },
  brand: {
    primary: '#D70915', //Colors/Epipoli/Primary/Brand
  },
  text: {
    black: '#000000',

    primary: "#080808",
    secondary: "#6E6E73",
    offBlack: "#080808"
  },
  common: {
    white: "#FFFFFF",
    secondary: "#626971",
    neutral95: '#F5F5F7',
    neutral90: '#EDEDED',
    neutral80: '#D9D9D9',
    neutral20: '#242426',
    neutral10: '#1D1D1F',
    maroonHalf: '#D7091580',
    maroon: '#D70915',
    maroonStrong: '#C40813',
    yellow: "#FFE57F",
    green: "#B9F6CA",
    lightGreen: "#BCEED1",
    lightBlue: "#D7D3FC",
    pink: '#F71B70',
    lightPink: "#F8D3D5",
    nesGray: "#AEAAAE",
    nesGreen: '#58FF09',
    nesGreenLight: '#58FF0980'
  },
};

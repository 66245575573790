import { Stack, TextField, Typography } from "@mui/material";
import TypographySmall, { TypographySmallStyle } from "./TypographySmall";
import { themeColors } from "../utils/themeColor";
import { themeFonts } from "../utils/themeFont";

export const InputStyle = {
  width: "-webkit-fill-available",
  height: "56px",
  margin: "0px",
  minWidth: "fit-content",
  border: `0.5px solid ${themeColors.common.secondary}`,
  borderRadius: "4px",
  fontFamily: themeFonts.regular,
  padding: "0px 8px 0px 12px",
  color: themeColors.text.primary,
  wordWrap: "break-word",
};

const LabelledInputMaterial = (props) => {
  const { label, containerMargin } = props;
  return (
    <Stack
      direction="column"
      alignItems="start"
      mb={3}
      gap="4px"
      sx={{
        width: "100%",
        margin: containerMargin,
      }}
    >
      <Typography
        sx={{
          color: themeColors.text.secondary,
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: "14px",
          fontFamily: themeFonts.medium,
        }}
      >
        {label}
      </Typography>
      <input
        {...props}
        label={label}
        style={InputStyle}
      />
    </Stack>
  );
};

export default LabelledInputMaterial;

import React, { useEffect, useState } from "react";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";

import {
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Heading } from "./OrderDetailsSummary";
import Button from "../../components/Button";
import { themeColors } from "../../utils/themeColor";
import ProductRow, {
  ProductTableCell,
  ROW_VARIANT_TOTAL,
} from "../../components/ProductRow";
import NespressoInvoiceDocument from "../../Pages/NespressoInvoiceDocument";
import Spacer from "../../components/Spacer";
import { STATE_CANCELLED, STATE_NEW } from "../../components/OrderState";
import { PDFDownloadLink } from "@react-pdf/renderer";

const OrderDetailsProducts = ({
  role,
  onMarkOrderPrepared,
  onMarkOrderCollectedConfirmed,
  onMarkOrderCancelledClickedInProd,
  orderDetails,
}) => {
  // console.log('orderDetails: ', orderDetails);
  const initialProductCheckedStatus = {};
  orderDetails?.items?.forEach((product) => {
    initialProductCheckedStatus[product.item_id] = false;
  });
  const [productCheckedStatus, setProductsCheckedStatus] = useState(
    initialProductCheckedStatus
  );
  const [enabledMarkPrepared, setEnableMarkPrepared] = useState(true);

  useEffect(() => {
    // console.log('productCheckedStatus', productCheckedStatus);
    // console.log('checkAllCheckboxChecked', checkAllCheckboxChecked());
    // if (checkAllCheckboxChecked()) {
    //   setEnableMarkPrepared(true);
    // } else {
    //   setEnableMarkPrepared(false);
    // }
  }, [productCheckedStatus]);

  const checkAllCheckboxChecked = () => {
    const values = Object.values(productCheckedStatus);
    let isCheckedAll = true;
    values.forEach((checkedStatus) => {
      if (checkedStatus === false) {
        // console.log(`checkedStatus: ${checkedStatus} checkedStatus === false ${(checkedStatus === false)}`);
        isCheckedAll = false;
      }
    });

    return isCheckedAll;
  };

  const onItemChecked = (checked, product) => {
    // console.log('product.id', product);
    // update individual checkbox status
    setProductsCheckedStatus({
      ...productCheckedStatus,
      [product.item_id]: checked,
    });
  };

  const onMarkPrepareClicked = () => {
    if (enabledMarkPrepared) {
      
      onMarkOrderPrepared();
    }
  };

  const onMarkOrderCancelledClicked = () => {
    
    onMarkOrderCancelledClickedInProd();
  };

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Heading
          sx={{
            marginY: {
              tablet: "18px",
              desktopSm: "26px",
              desktopLg: "26px",
            },
          }}
        >
          Elenco prodotti
        </Heading>
        <div>
          {orderDetails && (
            <PDFDownloadLink
              document={
                <NespressoInvoiceDocument orderDetails={orderDetails} />
              }
              fileName={`fattura-${orderDetails.increment_id}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  ""
                ) : (
                  <>
                    <Button
                      startIcon={<LocalPrintshopOutlinedIcon />}
                      type="tertiary"
                    >
                      Stampa
                    </Button>
                  </>
                )
              }
            </PDFDownloadLink>
          )}
        </div>
      </Stack>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: themeColors.common.white,
          width: "fill-available",
          boxShadow: "none",
        }}
      >
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow
              sx={{
                borderTop: `1px solid ${themeColors.common.neutral80}`,
                
              }}
            >
              <ProductTableCell align="left" variant="head">
                Prodotto
              </ProductTableCell>
              <ProductTableCell align="right" variant="head">
                Qtà astucci
              </ProductTableCell>
              <ProductTableCell align="right" variant="head">
                Prezzo
              </ProductTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderDetails?.items?.map((product) => (
              <ProductRow
                onItemChecked={onItemChecked}
                orderDetails={orderDetails}
                row={product}
              />
            ))}
            <ProductRow
              orderDetails={orderDetails}
              variant={ROW_VARIANT_TOTAL}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer space="16px" />
      <Stack direction="row" gap={2}>
        <Button
          disabled={orderDetails?.status !== STATE_NEW}
          styles={{
            height: "44px",
            width: "fit-content",
            marginY: "6px",
            padding: "14px 24px",
            fontSize: "18px",
            lineHeight: "21px",
            textAlign: "center",
          }}
          onClick={onMarkOrderCollectedConfirmed}
        >
          Segna come ritirato
        </Button>
        <Button
          disabled={orderDetails?.status == STATE_CANCELLED}
          type="secondary"
          styles={{
            height: "44px",
            width: "fit-content",
            marginY: "6px",
            padding: "14px 24px",
            fontSize: "18px",
            lineHeight: "21px",
            textAlign: "center",
          }}
          onClick={onMarkOrderCancelledClickedInProd}
        >
          Segna come annullato
        </Button>
      </Stack>
    </Stack>
  );
};

export default OrderDetailsProducts;

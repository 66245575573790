import Select, { components } from "react-select";
import IconArrowDownDark from "../../src/assets/images/ic-chevron-down.svg";
import IconArrowUpDark from "../../src/assets/images/ic-chevron-up.svg";
import { themeColors } from "../utils/themeColor";

const NespressoSelect = (props) => {
  const { options, selectedStore, onSelect } = props;

  const DropdownIndicator = (props) => {
    const {
      selectProps: { menuIsOpen },
    } = props;

    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? (
          <img
            src={IconArrowUpDark}
            style={{
              width: 16,
              height: 16,
            }}
          />
        ) : (
          <img
            src={IconArrowDownDark}
            style={{
              width: 16,
              height: 16,
            }}
          />
        )}
      </components.DropdownIndicator>
    );
  };

  const getOptions = () => {
    const storeName = [];
    for (let index = 0; index < options.length; index++) {
      storeName.push({
        value: options[index].store_id,
        label: options[index].name,
        data: options[index],
      });
    }

    return storeName;
  };

  return (
    <Select
      value={getOptions().filter(
        (option) => option.value === selectedStore?.store_id
      )}
      onChange={(selectedOption) => {
        onSelect(selectedOption.data);
      }}
      isSearchable={false}
      options={getOptions()}
      placeholder="Tutti gli store"
      components={{ DropdownIndicator }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          background: themeColors.common.neutral95,
          height: "56px",
          width: "300px",
          padding: "10px",
          cursor: 'pointer',
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          height: "22px",
          color: themeColors.common.nesGray,
        }),
        indicatorSeparator: (baseStyles, state) => ({
          display: "none",
        }),
        option: (base, state) => ({
          ...base,
          color: themeColors.text.primary,
          background: "white",
          height: "44px",
          cursor: 'pointer',
        }),
      }}
    />
  );
};

export default NespressoSelect;

import { themeFonts } from "../utils/themeFont";
import { themeColors } from "../utils/themeColor";
import { styled, Box, useMediaQuery, useTheme } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import IconArrowRight from "../../src/assets/images/ic-arrow-right.svg";
import IconCheckboxChecked from "../../src/assets/images/ic-checkbox-checked.svg";
import { useNavigate } from "react-router-dom";
import { StyledTableRow } from "./OrderRowRetailer";
import "../assets/Css/mainOriginalList.css";
import {
  getFormattedDate,
  getFormattedDateTime,
  trimEllip,
} from "../utils/utils";
import OrderState from "./OrderState";

export const StyledTableAdminCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: themeColors.text.secondary,
    fontFamily: themeFonts.regular,
    // background: '#0000FF20',
    lineHeight: "15px",
    fontSize: "16px",
    padding: "0px",
    margin: "0px",
    "&:nth-child(8)": {
      width: "100px",
    },
    "&:nth-child(9)": {
      width: "50px",
    },
    "&:nth-child(1)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "&.MuiTableCell-head": {
    color: theme.palette.common.black,
    wordWrap: "break-word",
    lineHeight: "15px",
    fontSize: "15px",
    padding: "0px",
    margin: "0px",
    height: "32px",
    fontFamily: themeFonts.medium,
    "&:nth-child(1)": {
      width: "130px",
    },
    "&:nth-child(2)": {
      width: "120px",
    },
    "&:nth-child(3)": {
      width: "80px",
    },
    "&:nth-child(4)": {
      width: "110px",
    },
    "&:nth-child(5)": {
      width: "110px",
    },
    "&:nth-child(6)": {
      width: {
        tablet: "246px",
        desktopSm: "230px",
        desktopLg: "230px",
      },
      minWidth: "256px",
    },
    "&:nth-child(7)": {
      width: "150px",
    },
  },
  "&:first-child": {
    padding: "8px 0px 8px 12px",
  },
  "&:last-child": {
    padding: "8px 12px 8px 0px",
  },
  // TODO finish this
  /*   "&:first-child": {
    padding: "16px 0px 16px 12px",
  },
  "&:last-child": {
    padding: "16px 12px 16px 0px",
  }, */
}));

const OrderRowAdmin = ({ order }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("desktopSm"));

  const onOrderClicked = () => {
    window.location.href = `/orderDetails?id=${order.entity_id}`;
  };

  const getUserBaseDateTime = () => {
    if (isTablet) {
      return getFormattedDate(order?.created_at);
    }
    return getFormattedDateTime(order?.created_at);
  };
  
  const formattedPrice = order.grand_total.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  });


  return (
    <>
      <StyledTableRow key={order.increment_id}>
        <StyledTableAdminCell align="left">
          {trimEllip(order.store_name, 10)}
        </StyledTableAdminCell>
        <StyledTableAdminCell component="th" scope="row">
          {order.increment_id}
        </StyledTableAdminCell>
        <StyledTableAdminCell align="left">
          € {formattedPrice}
        </StyledTableAdminCell>
        <StyledTableAdminCell align="left">
          {order.customer_firstname}
        </StyledTableAdminCell>
        <StyledTableAdminCell align="left">
          {order.customer_lastname}
        </StyledTableAdminCell>
        <StyledTableAdminCell align="left">
          {order.customer_email}
        </StyledTableAdminCell>
        <StyledTableAdminCell align="left">
          {getUserBaseDateTime()}
        </StyledTableAdminCell>
        <StyledTableAdminCell align="left">
          <OrderState value={order.status} />
        </StyledTableAdminCell>
        <StyledTableAdminCell align="center">
          {order.extension_attributes.order_check_flag == 1 && (
            <img src={IconCheckboxChecked} height="14px" width="14px" />
          )}
        </StyledTableAdminCell>
        <StyledTableAdminCell align="right">
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              height: "fit-content",
              cursor: "pointer",
            }}
            onClick={onOrderClicked}
          >
            <img src={IconArrowRight} height="24px" width="24px" />
          </Box>
        </StyledTableAdminCell>
      </StyledTableRow>
    </>
  );
};

export default OrderRowAdmin;

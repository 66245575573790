import { Toolbar as BaseToolbar, styled } from "@mui/material";

// this component is specifically used like this coz we are using a height equivalent
// of it when showing the sidebar.

const Toolbar = styled(BaseToolbar)((props) => ({
  padding: "0px",
  height: "32px",
  minHeight: "0px",
}));

export default Toolbar;

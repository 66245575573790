import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Stack, Typography } from "@mui/material";
import { themeColors } from "../utils/themeColor";
import { themeFonts } from "../utils/themeFont";

const Notification = (props) => {
  const {
    newOrderCount,
    onClose
  } = props;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        height: "32px",
        backgroundColor: themeColors.common.pink,
        paddingX: 2,
        paddingY: "2px",
      }}
    >
      <Typography
        sx={{
          color: "white",
          fontSize: "18px",
          textAlign: "center",
          fontFamily: themeFonts.medium,
          fontWeight: 500,
          wordWrap: "break-word",
        }}
      >
        Ci sono {newOrderCount} nuovi ordini
      </Typography>
      <CloseIcon
        sx={{
          color: "white",
        }}
        onClick={onClose}
      />
    </Stack>
  );
};

export default Notification;

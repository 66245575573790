import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

import Spacer from "../../components/Spacer";
import { themeColors } from "../../utils/themeColor";
import { themeFonts } from "../../utils/themeFont";
import Card from "../../components/Card";
import Logo from "../../assets/images/logo-large.svg";
import StateLogin from "./StateLogin";
import StateResetPasswordRequested from "./StateResetPasswordRequested";
import StateSetPassword from "./StateSetPassword";
import { BROADCAST_CHANNEL_ID_LOGIN_PAGE } from "../../utils/constants";
import { useEffect } from "react";
import StateSendEmail from "./StateSendEmail";

export const STATE_LOGIN = "login";
export const STATE_SEND_EMAIL = "reset-pass";
export const STATE_RESET_PASSWORD_REQUESTED = "reset-pass-requested";
export const STATE_SET_PASSWORD = "set-password";

export const CardStyle = {
  width: "402px",
  padding: "40px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  background: "#FFFFFF",
};

const Login = () => {
  const [uiState, setUiState] = useState(STATE_LOGIN);
  const channel = new BroadcastChannel(BROADCAST_CHANNEL_ID_LOGIN_PAGE);

  const getSpaceBelowImage = () => {
    /*if(uiState === STATE_LOGIN) {
      return "16px";  
    } else if(uiState === STATE_SET_PASSWORD) {
      return "16px";  
    } */
    return "14px";
  };

  useEffect(() => {
    channel.postMessage("FROMLOGIN");
  }, [window.location.pathname]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100vw",
        height: "100vh",
        background: themeColors.common.neutral95,
      }}
    >
      <Card sx={CardStyle}>
        <img src={Logo} />
        <Spacer space={getSpaceBelowImage()} />
        {uiState === STATE_LOGIN && <StateLogin setUiState={setUiState} />}
        {uiState === STATE_SEND_EMAIL && (
          <StateSendEmail setUiState={setUiState} />
        )}
        {uiState == STATE_RESET_PASSWORD_REQUESTED && (
          <StateResetPasswordRequested setUiState={setUiState} />
        )}
        {uiState == STATE_SET_PASSWORD && (
          <StateSetPassword setUiState={setUiState} />
        )}
      </Card>
    </Stack>
  );
};

export default Login;

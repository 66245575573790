import * as React from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { themeColors } from "../utils/themeColor";
import { TypographyMediumStyle } from "./TypographyMedium";
import Logo from "../assets/images/logo.svg";
import TypographyMedium from "./TypographyMedium";
import {
  ROLE_SUPER_ADMIN,
  PERSISTANT_STORAGE_KEY_USER,
  PERSISTANT_STORAGE_KEY_SELECTED_STORE,
} from "../utils/constants";
import { useTheme } from "@mui/material/styles";
import {
  ButtonBase,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Toolbar from "./Toolbar";
import {
  ROUTE_ALL_ORDERS,
  ROUTE_HOME,
  ROUTE_INVENTORY,
  ROUTE_LOGIN,
  ROUTE_ORDERS,
} from "../App";
import NespressoSelect from "./NespressoSelect";
import { clearLocalStorage, useLocalStorage } from "../utils/localStorage";
import { useState } from "react";
import storesService from "../api/services/storesService";
import { useEffect } from "react";

const Header = ({ onStoreChange }) => {
  const channel = new BroadcastChannel(ROLE_SUPER_ADMIN);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const navigate = useNavigate();
  const [getUserDetails, saveUserDetails] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_USER
  );
  const [getStoreData, saveStoreData] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_SELECTED_STORE
  );
  const userDetails = JSON.parse(getUserDetails(PERSISTANT_STORAGE_KEY_USER));
  const [errorMessage, setErrorMessage] = useState(null);

  const role = userDetails?.user_type;
  const pathName = window.location.pathname;

  useEffect(() => {
    if (!userDetails) {
      return;
    }

    (async () => {
      try {
        const results = await storesService.getAll();
        // console.log('storesService.results: ', results);
        setStores(results?.items);

        if (results?.items && results?.items?.length > 0) {
          const firstStore = results?.items[0];
          // console.log("firstStore: ", firstStore);
        }
      } catch (error) {
        console.log("error:", error.message);
        setErrorMessage(error.message);
      }
    })();
  }, [storesService]);

  useEffect(() => {
    setTimeout(() => {
      const storeId = getStoreData();
      if (stores) {
        stores.forEach((store) => {
          if (store.store_id === storeId) {
            setSelectedStore(store);
          }
        });
      }
    }, 200);
  }, [stores]);

  const logout = () => {
    clearLocalStorage();
    navigate(`${ROUTE_LOGIN}`);
  };

  useEffect(() => {
    if (!userDetails?.token) {
      // force redirect if user is already logged in
      logout();
    }
  }, [userDetails?.token, logout]);

  const onStoreSelected = (store) => {
    onStoreChange(store);
  };

  const areLandingPaths = () => {
    return (
      pathName === ROUTE_HOME ||
      pathName === ROUTE_ORDERS ||
      pathName === ROUTE_INVENTORY
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          background: themeColors.common.white,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          padding: {
            tablet: "24px 32px",
            desktopSm: "24px",
            desktopLg: "24px",
          },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              display: {
                tablet: "flex",
                desktopSm: "none",
                desktopLg: "none",
              },
            }}
            onClick={() => {
              channel.postMessage("post-message");
            }}
            /*  onKeyDown={() => {
              console.log('onkeydown');
              toggleDrawer('left', true);
            }} */
          >
            <MenuIcon
              sx={{
                color: themeColors.text.primary,
              }}
            />
          </IconButton>
          <Stack
            direction="row"
            sx={{ flexGrow: 1, padding: "0px" }}
            onClick={() => {
              navigate(`${ROUTE_HOME}`);
            }}
            gap={4}
          >
            <img
              src={Logo}
              style={{
                cursor: "pointer",
              }}
            />
            {role === ROLE_SUPER_ADMIN && areLandingPaths() && (
              <>
                <Divider orientation="vertical" sx={{ height: "56px" }} />
                <NespressoSelect
                  options={stores}
                  selectedStore={selectedStore}
                  onSelect={onStoreSelected}
                />
              </>
            )}
          </Stack>
          <Button
            color="inherit"
            sx={{
              color: themeColors.text.secondary,
              ...TypographyMediumStyle,
              textTransform: "none",
            }}
            onClick={logout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;

import React, { useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";

import Spacer from "../../components/Spacer";
import { themeFonts } from "../../utils/themeFont";
import LabelledInputMaterial from "../../components/LabelledInputMaterial";
import Button from "../../components/Button";
import {
  STATE_LOGIN,
  STATE_SEND_EMAIL,
  STATE_RESET_PASSWORD_REQUESTED,
} from "./LoginPage";
import { themeColors } from "../../utils/themeColor";
import authService from "../../api/services/authService";
import { validateEmail } from "../../utils/utils";

const StateSendEmail = ({ setUiState }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const onFieldChanged = (event) => {
    const target = event.target;
    const fieldValue = target.value;
    // console.log("value: ", event.target.value);
    // console.log("id: ", event.target.id);
    event.preventDefault();
    setEmail(fieldValue);
    setErrorMessage(null);
  };

  const validate = () => {
    if (email == null) {
      setErrorMessage("Inserisci l'e-mail");
      return false;
    }

    if (!validateEmail(email) || email?.length < 4) {
      setErrorMessage("Invalid email");
      return false;
    }

    return true;
  };

  const requestPasswordResetEmail = async () => {
    
    if(!validate()) {
      return;
    }

    try {
      setLoading(true);
      let results = await authService.requestPasswordResetEmail(email);
      
      setUiState(STATE_RESET_PASSWORD_REQUESTED);
      setLoading(false);
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
      }}
    >
      <Typography
        mb={2}
        sx={{
          maxWidth: "320px",
          color: themeColors.common.neutral10,
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "16px",
          fontFamily: themeFonts.regular,
          wordWrap: "break-word",
          textAlign: "center",
        }}
      >
        Inserisci il tuo indirizzo email e ti invieremo un link per resettare la
        tua password.
      </Typography>
      <LabelledInputMaterial
        label="Email"
        id="email"
        type="text"
        onChange={onFieldChanged}
      />
      <Typography
        sx={{
          color: themeColors.common.maroon,
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: themeFonts.medium,
          wordWrap: "break-word",
        }}
        my={errorMessage ? 2 : 0}
      >
        {errorMessage}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack
          gap={1}
          mt={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            mt="6px"
            mb="6px"
            type="dull"
            onClick={() => {
              setUiState(STATE_LOGIN);
            }}
          >
            Annulla
          </Button>
          <Button
            mt="6px"
            mb="6px"
            onClick={() => {
              requestPasswordResetEmail();
            }}
          >
            Avanti
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default StateSendEmail;

import React, { useEffect, useState } from "react";
import { Table, TableBody } from "@mui/material";

import OrderRow from "../../components/OrderRowRetailer";
import SearchInput from "../../components/SearchInput";
import { TableHeaderRetailer, rows } from "./ActiveOrders";
import Pagination from "../../components/Pagination";
import TableContainer from "../../components/TableContainer";
import Container from "../../components/Container";
import ordersService from "../../api/services/ordersService";
import {
  EMPTY_STATE_MESSAGE,
  PAGE_SIZE,
  PERSISTANT_STORAGE_KEY_SELECTED_STORE,
  PERSISTANT_STORAGE_KEY_USER,
} from "../../utils/constants";
import {
  STATE_CANCELLED,
  STATE_NEW,
  STATE_PICKED_UP,
  STATE_READY,
} from "../../components/OrderState";
import { useLocalStorage } from "../../utils/localStorage";
import ProgressOrMessageIndicator from "../../components/ProgressErrorStateIndicator";

const PastOrders = () => {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchCache2, setSearchCache2] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [page, setPage] = useState(1);
  const [getUserDetails, saveUserDetails] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_USER
  );
  const [getStoreData, saveStoreData] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_SELECTED_STORE
  );
  const userDetails = JSON.parse(getUserDetails(PERSISTANT_STORAGE_KEY_USER));
  const role = userDetails.user_type;

  const searchOrders = async (searchQuery, pageNo) => {
    try {
      setLoading(true);
      let results = await ordersService.search(searchQuery, pageNo);
      const filterGroups = results?.search_criteria?.filter_groups;
      const firstFilterGroup =
        filterGroups?.length > 0 ? filterGroups[0] : null;
      // console.log("firstFilterGroup: ", firstFilterGroup.filters);
      const firstFilter =
        firstFilterGroup?.filters?.length > 0
          ? firstFilterGroup?.filters[0]
          : null;
      // console.log("firstFilter: ", firstFilter);
      if (firstFilter?.value === searchQuery) {
        setSearchCache2({
          ...searchCache2,
          [firstFilter?.value]: results,
        });
      }
      // console.log("search.results.items", results?.items);
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  const getOrders = async (storeId, pageNo) => {
    try {
      let results = [];
      setLoading(true);
      if (storeId == null) {
        results = await ordersService.getAll(pageNo);
      } else {
        results = await ordersService.getFilteredOrders(
          storeId,
          pageNo,
          `${STATE_PICKED_UP},${STATE_CANCELLED}`
        );
      }

      setOrders(results?.items?.filter((obj) => obj.status !== "pending"));
      setTotalItems(results?.items?.length);
      setLoading(false);
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    
    if (!searchQuery) {
      const storeId = getStoreData();
      getOrders(storeId, 1);
    } else {
      searchOrders(searchQuery, 1);
    }
  }, [searchQuery]);

  useEffect(() => {
    
    const results = searchCache2 ? searchCache2[searchQuery] : null;
    if (results != null) {
      setOrders(results.items?.filter((obj) => obj.status !== "pending"));
      setTotalItems(results.total_count);
      setLoading(false);
    }
  }, [searchCache2]);

  const onPageChanged = (pageNo) => {
    if (!searchQuery) {
      const storeId = JSON.parse(getStoreData());
      getOrders(storeId, pageNo);
    } else {
      searchOrders(searchQuery, pageNo);
    }
  };

  return (
    <Container>
      {(searchQuery?.length > 0 || orders?.length > 0) && (
        <SearchInput
          onQuery={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      )}
      <TableContainer>
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          {loading ? (
            <ProgressOrMessageIndicator />
          ) : orders?.length == 0 ? (
            <ProgressOrMessageIndicator message={EMPTY_STATE_MESSAGE} />
          ) : (
            <>
              <TableHeaderRetailer />
              <TableBody>
                {orders?.map((order) => (
                  <OrderRow order={order} activeOrder={false} />
                ))}
              </TableBody>
            </>
          )}
        </Table>
        {orders?.length > 0 && totalItems > PAGE_SIZE && (
          <Pagination
            pageCount={Math.ceil(totalItems / PAGE_SIZE)}
            onChange={onPageChanged}
          />
        )}
      </TableContainer>
    </Container>
  );
};

export default PastOrders;

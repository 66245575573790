import { styled } from "@mui/material";
import { themeFonts } from "../utils/themeFont";
import { themeColors } from "../utils/themeColor";

const H2 = styled('h2')(({ theme, color }) => ({
  fontWeight: 300,
  fontSize: '40px',
  color: themeColors.common.neutral10,
  fontFamily: themeFonts.light,
  lineHeight: '45.96px',
  margin: '0px',
}));

export default H2;
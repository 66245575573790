import React from "react";
import {
  AppBar,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";

import { StyledTableRow } from "../components/OrderRowRetailer";
import SearchInput from "../components/SearchInput";
import TableContainer from "../components/TableContainer";
import HeadingContainer from "../components/HeadingContainer";
import PageHeading from "../components/PageHeading";
import Spacer from "../components/Spacer";
import { themeColors } from "../utils/themeColor";
import { themeFonts } from "../utils/themeFont";
import Container from "../components/Container";
import Pagination from "../components/Pagination";
import { useState } from "react";
import inventoryService from "../api/services/inventoryService";
import { useEffect } from "react";
import ProgressOrMessageIndicator from "../components/ProgressErrorStateIndicator";
import {
  EMPTY_STATE_MESSAGE,
  PAGE_SIZE,
  PERSISTANT_STORAGE_KEY_SELECTED_STORE,
  PERSISTANT_STORAGE_KEY_USER,
  ROLE_SUPER_ADMIN,
} from "../utils/constants";
import { useLocalStorage } from "../utils/localStorage";
import { trimEllip } from "../utils/utils";

const MagazzinoTableCell = styled(TableCell)(({ theme, label }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: themeColors.text.secondary,
    padding: "0px",
    fontFamily: themeFonts.regular,
    fontSize: "16px",
    lineHeight: "25px",
  },
  "&.MuiTableCell-head": {
    color: theme.palette.common.black,
    wordWrap: "break-word",
    lineHeight: "15px",
    fontSize: "15px",
    fontFamily: themeFonts.medium,
    /* "&:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), , &:nth-child(6)": {
      width: "123px",
      maxWidth: "123px"
    }, */
    "&:first-child": {
      width:
        label === "prodotto" ? "200px" : label === "store" ? "150px" : "auto",
    },
  },
  "&:first-child": {
    padding: "16px 0px 16px 12px",
  },
  "&:last-child": {
    padding: "16px 12px 16px 0px",
  },
  padding: "0px",
}));

const MagazzinoRow = ({ row, role }) => {
  return (
    <>
      <StyledTableRow key={row.id}>
        {role === ROLE_SUPER_ADMIN && (
          <MagazzinoTableCell component="th" scope="row">
            {trimEllip(row.website, 12)}
          </MagazzinoTableCell>
        )}
        <MagazzinoTableCell component="th" scope="row">
          {row.name}
        </MagazzinoTableCell>
        <MagazzinoTableCell align="center">{row.code}</MagazzinoTableCell>
        {/* <MagazzinoTableCell align="right">{row.price}</MagazzinoTableCell> */}
        <MagazzinoTableCell align="right">{row.stock_qty}</MagazzinoTableCell>
        <MagazzinoTableCell align="right">{row.blocked_qty}</MagazzinoTableCell>
        <MagazzinoTableCell align="right">
          {row.available_qty}
        </MagazzinoTableCell>
      </StyledTableRow>
    </>
  );
};

const TableHeader = ({ role }) => {
  return (
    <>
      <TableHead>
        <TableRow>
          {role === ROLE_SUPER_ADMIN && (
            <MagazzinoTableCell variant="head" align="left" label="store">
              Store
            </MagazzinoTableCell>
          )}
          <MagazzinoTableCell variant="head" align="left" label="prodotto">
            Prodotto
          </MagazzinoTableCell>
          <MagazzinoTableCell variant="head" align="center">
            Codice
          </MagazzinoTableCell>
          {/* <MagazzinoTableCell variant="head" align="right">
            Prezzo
          </MagazzinoTableCell> */}
          <MagazzinoTableCell variant="head" align="right">
            Qtà stock
          </MagazzinoTableCell>
          <MagazzinoTableCell variant="head" align="right">
            Qtà bloccata
          </MagazzinoTableCell>
          <MagazzinoTableCell variant="head" align="right">
            Disponibilità
          </MagazzinoTableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

const Magazzino = () => {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchCache2, setSearchCache2] = useState(null);
  const [uiMessage, setUiMessage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [page, setPage] = useState(1);
  const [getUserDetails, saveUserDetails] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_USER
  );
  const [getStoreData, saveStoreData] = useLocalStorage(
    PERSISTANT_STORAGE_KEY_SELECTED_STORE
  );
  const userDetails = JSON.parse(getUserDetails(PERSISTANT_STORAGE_KEY_USER));
  const role = userDetails?.user_type;

  const getInventory = async (pageNo) => {
    try {
      const storeId = getStoreData();
      setLoading(true);
      let results = await inventoryService.getAll(storeId, pageNo);

      setLoading(false);
      if (results?.items?.length > 0) {
        setOrders(results?.items);
        setTotalPages(results?.total_pages);
      } else {
        setUiMessage(EMPTY_STATE_MESSAGE);
      }
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setUiMessage(error.message);
    }
  };

  const searchOrders = async (searchQuery, pageNo) => {
    try {
      const storeId = getStoreData();
      setLoading(true);
      let results = await inventoryService.search(searchQuery, storeId, pageNo);

      const searchString = results?.search_string;
      if (searchString === searchQuery) {
        setSearchCache2({
          ...searchCache2,
          [searchString]: results,
        });
      }
      // console.log("search.results.items", results?.items);
    } catch (error) {
      console.log("error:", error.message);
      setLoading(false);
      setUiMessage(error.message);
    }
  };

  useEffect(() => {
    
    if (!searchQuery) {
      getInventory(1);
    } else {
      searchOrders(searchQuery, 1);
    }
  }, [inventoryService, searchQuery]);

  useEffect(() => {
    const results = searchCache2 ? searchCache2[searchQuery] : null;
    if (results != null) {
      setOrders(results.items);
      setLoading(false);
      setTotalPages(results.total_pages);
    }
  }, [searchCache2]);

  const onPageChanged = (pageNo) => {
    if (!searchQuery) {
      getInventory(pageNo);
    } else {
      searchOrders(searchQuery, pageNo);
    }
  };

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <HeadingContainer>
        <PageHeading title="Magazzino" />
        <Spacer space="12px" />
      </HeadingContainer>
      <Container>
        {(searchQuery?.length > 0 || orders?.length > 0) && (
          <SearchInput
            onQuery={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        )}
        <TableContainer>
          <Table sx={{ minWidth: 300 }} aria-label="customized table">
            {loading ? (
              <ProgressOrMessageIndicator />
            ) : orders?.length == 0 ? (
              <ProgressOrMessageIndicator message={EMPTY_STATE_MESSAGE} />
            ) : (
              <>
                <TableHeader role={role} />
                <TableBody>
                  {orders?.map((order) => {
                    return <MagazzinoRow row={order} role={role} />;
                  })}
                </TableBody>
              </>
            )}
          </Table>
          {orders?.length > 0 && totalPages > 1 && (
            <Pagination
              pageCount={Math.ceil(totalPages)}
              onChange={onPageChanged}
            />
          )}
        </TableContainer>
      </Container>
    </Stack>
  );
};

export default Magazzino;
